import React from 'react'
import RechargeNow from '../Components/Balance/RechargeNow'

function RechargeGifziPlus() {
  return (
    <div>
        <RechargeNow/>
    </div>
  )
}

export default RechargeGifziPlus