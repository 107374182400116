import React from 'react'
import Banner from '../Components/About/Banner/Banner'
import Step from '../Components/Step/Step'
import Download from '../Components/Download/Download'
import Mission from '../Components/Mission/Mission'
import Team from '../Components/About/Team/Team'
import Whoweare from '../Components/About/Whoweare/Whoweare'
import Hand from '../Components/About/Banner/Hand'

function About() {
  return (
    <div>

      <Banner/>
      <Hand/>
      <Whoweare/>
      <Mission />
      <Step/>
      <Team/>
      <Download/>
    </div>
  )
}

export default About