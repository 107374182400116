import React, { useState, useEffect } from 'react';
import './Askfaq.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faWallet, faBan, faTruck, faDollarSign, faTag } from '@fortawesome/free-solid-svg-icons';

function Askfaq() {
  // State to store fetched FAQ data
  const [faqData, setFaqData] = useState([]);
  const [error, setError] = useState(false);

  // Function to fetch FAQs from the API
  const fetchFaqs = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}menu/faqs?offset=0&limit=10`);
      const data = await response.json();

      if (data.status === 200) {
        setFaqData(data.data.faqs);  // Setting fetched FAQs
      } else {
        setError(true);
      }
    } catch (err) {
      console.error('Error fetching FAQs:', err);
      setError(true);
    }
  };

  // Fetch FAQs when component mounts
  useEffect(() => {
    fetchFaqs();
  }, []);

  // Icons to match with FAQs
  const icons = [faEnvelope, faWallet, faBan, faTruck, faDollarSign, faTag];

  return (
    <div>
      <section className="faq-hed-sec">
        <div className="container">
          <div className="row">
            <div className="faq-hed">            
              <span className="f-12">Faq</span>
              <h3 className="f-40">Ask us anything</h3>
              <p className="f-16">Have any questions? We're here to assist you.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="faq-body"> 
        <div className="container">
          <div className="row">
            {error ? (
              <p>Failed to load FAQs. Please try again later.</p>
            ) : faqData.length === 0 ? (
              <p>Loading FAQs...</p>
            ) : (
              faqData.map((faq, index) => (
                <div key={index} className={`col-md-4 d-flex ${index === 3 ? 'rotate-faq' : ''}`}>
                  <div className="faq-card">
                    <FontAwesomeIcon icon={icons[index % icons.length]} /> 
                    <h3 className="f-20">{faq.question}</h3> 
                    <p className="f-14">{faq.answer}</p>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </section>

      <section className="gettouch">
        <div className="container">
          <div className="row ">
            <div className="faq-hed shadow-sm">            
              <h3 className="f-40 f-weight-600 text-dark mb-2">Get In Touch </h3>
              <p className="f-28 text-muted">Want to get in touch? We'd love to hear from you</p>
              <div className='d-flex-center gap-2 mt-2'>
              <i className='whatsappicon'>
              <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M28.8167 23.8331C28.4334 23.6414 25.9417 22.4914 25.5584 22.2997C25.175 22.1081 24.7917 22.1081 24.4084 22.4914C24.025 22.8747 23.2584 24.0247 22.875 24.4081C22.6834 24.7914 22.3 24.7914 21.9167 24.5997C20.575 24.0247 19.2334 23.2581 18.0834 22.2997C17.125 21.3414 16.1667 20.1914 15.4 19.0414C15.2084 18.6581 15.4 18.2747 15.5917 18.0831C15.7834 17.8914 15.975 17.5081 16.3584 17.3164C16.55 17.1247 16.7417 16.7414 16.7417 16.5497C16.9334 16.3581 16.9334 15.9747 16.7417 15.7831C16.55 15.5914 15.5917 13.2914 15.2084 12.3331C15.0167 10.9914 14.6334 10.9914 14.25 10.9914H13.2917C12.9084 10.9914 12.3334 11.3747 12.1417 11.5664C10.9917 12.7164 10.4167 14.0581 10.4167 15.5914C10.6084 17.3164 11.1834 19.0414 12.3334 20.5747C14.4417 23.6414 17.125 26.1331 20.3834 27.6664C21.3417 28.0497 22.1084 28.4331 23.0667 28.6247C24.025 29.0081 24.9834 29.0081 26.1334 28.8164C27.475 28.6247 28.625 27.6664 29.3917 26.5164C29.775 25.7497 29.775 24.9831 29.5834 24.2164L28.8167 23.8331ZM33.6084 6.39141C26.1334 -1.08359 14.0584 -1.08359 6.58337 6.39141C0.450041 12.5247 -0.699959 21.9164 3.51671 29.3914L0.833374 39.1664L10.9917 36.4831C13.8667 38.0164 16.9334 38.7831 20 38.7831C30.5417 38.7831 38.975 30.3497 38.975 19.8081C39.1667 14.8247 37.0584 10.0331 33.6084 6.39141ZM28.4334 33.2247C25.9417 34.7581 23.0667 35.7164 20 35.7164C17.125 35.7164 14.4417 34.9497 11.95 33.6081L11.375 33.2247L5.43337 34.7581L6.96671 29.0081L6.58337 28.4331C1.98337 20.7664 4.28337 11.1831 11.7584 6.39141C19.2334 1.59974 28.8167 4.09141 33.4167 11.3747C38.0167 18.8497 35.9084 28.6247 28.4334 33.2247Z" fill="#34C759"></path></svg>
                </i>
                <span className='f-28 text-dark'>+911574358512</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Askfaq;
