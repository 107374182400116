import React from 'react'
import Setpassword from '../Components/Authentication/Setpassword/Setpassword'

function Newpassword() {
  return (
    <div>
        <Setpassword/>
    </div>
  )
}

export default Newpassword