import React, { useState } from 'react';
import confetti from 'canvas-confetti';
import './Success.css';

const Success = ({ onClick }) => {
  const [buttonState, setButtonState] = useState('default'); // 'default', 'loading', 'success'
  const [buttonText, setButtonText] = useState('Get Gifzi Plus');
  const [showAlert, setShowAlert] = useState(false);

  const startConfetti = () => {
    // Immediately set the button to success state
    setButtonState('success');
    setButtonText('Card Created');
    setShowAlert(true);

    const button = document.getElementById('button-conf');
    if (!button) {
      console.error('Button not found. Ensure the button has the correct ID.');
      return;
    }

    const rect = button.getBoundingClientRect();
    const center = {
      x: rect.left + rect.width / 2,
      y: rect.top + rect.height / 2,
    };
    const origin = {
      x: center.x / window.innerWidth,
      y: center.y / window.innerHeight,
    };

    console.log('Confetti center:', center, 'Origin:', origin);

    function fire(particleRatio, opts) {
      confetti(
        Object.assign({}, opts, {
          particleCount: Math.floor(100 * particleRatio),
          origin,
          colors: ['#4C093F', '#FFCC00', '#34C759'],
          particleCount: 100,
          spread: 70,
          zIndex: 9999,
        })
      );
    }

    // Trigger additional functionality

    // Trigger confetti effects
    fire(0.25, { spread: 26, startVelocity: 10 });
    fire(0.2, { spread: 60, startVelocity: 20 });
    fire(0.35, { spread: 100, startVelocity: 15 });
    fire(0.1, { spread: 120, startVelocity: 10 });
    fire(0.1, { spread: 120, startVelocity: 20 });

    // Reset the button after 3 seconds
    setTimeout(() => {
      setShowAlert(false);
      setButtonState('default');
      setButtonText('Get Gifzi Plus');
      onClick();
    }, 3000);

    
  };

  return (
    <>
      {showAlert && (
        <div className="alert">
          ✨ Gift Plus card is created successfully ✨
        </div>
      )}
      <button
        id="button-conf"
        className={`common-btn ${buttonState}`}
        onClick={startConfetti}
        disabled={buttonState === 'loading'}
      >
        <span className="text">
          {buttonText}
        </span>
      </button>
    </>
  );
};

export default Success;
