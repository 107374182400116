import React from 'react'
import List from '../Components/Blog/List'

function AllBlog() {
  return (
    <div>

        <List/>
    </div>
  )
}

export default AllBlog