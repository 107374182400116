import React from 'react';
import './CategoryTab.css'

function CategoryTab({ heading, description, img }) {
  return (
    <div className='tab-detail d-flex'>
      <div className='content'>
        <h2 className='f-24 mb-2 f-weight-600'>{heading}</h2>
        <p className='f-14 text-grey'>{description}</p>
      </div>
      <div className='cat-tab-img'>
        <i className='icon-cat '>
          <img className="rounded" src={img} alt={heading} height="100%" width="100%" />
        </i>
      </div>
    </div>
  );
}

export default CategoryTab;
