import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../Commonbtn/Common.css'

function Password(props) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className='form-group position-relative'>
      <label className="label" htmlFor={props.fortext1}>
        {props.labeltext1}
      </label>
      <input
        type={isPasswordVisible ? 'text' : 'password'} // Toggle between text and password
        placeholder={props.placeholdertext1}
        className="form-control shadow-sm"
        name={props.name}
        value={props.value}
        onChange={props.onChange}
      />
      {/* Toggle between eye and eye-slash icon based on visibility */}
      <span className='password-eye' onClick={togglePasswordVisibility}>
        <FontAwesomeIcon icon={isPasswordVisible ? faEyeSlash : faEye} />
      </span>
    </div>
  );
}

export default Password;
