import React, { useState } from "react";
import "./WriteReview.scss";

const WriteReview = ({ productId }) => {
  const [rating, setRating] = useState(0);
  const [ratingMessage, setRatingMessage] = useState("");
  const [isReviewEnabled, setIsReviewEnabled] = useState(false);
  const [tagsVisible, setTagsVisible] = useState(null);
  const [complimentVisible, setComplimentVisible] = useState(false);

  const starsData = [
    { title: "1 star", message: "Poor", value: 1 },
    { title: "2 stars", message: "Too bad", value: 2 },
    { title: "3 stars", message: "Average quality", value: 3 },
    { title: "4 stars", message: "Nice", value: 4 },
    { title: "5 stars", message: "Very good quality", value: 5 },
  ];

  const handleStarHover = (value) => {
    setRating(value);
  };

  const handleStarClick = (value, message) => {
    setRating(value);
    setRatingMessage(message);
    setTagsVisible(value);
    setComplimentVisible(value === 5);
    setIsReviewEnabled(value === 5);
  };

  return (
    <div className="write-review">
  <div className="wrapper">
      <div className="master">
        <h1>Review And Rating</h1>
        <h2>How was your experience about our product?</h2>

        <div className="rating-component">
          <div className="status-msg">
            <label>
              <input className="rating_msg" type="hidden" name="rating_msg" value={ratingMessage} />
            </label>
          </div>
          <div className="stars-box">
            {starsData.map((star) => (
              <i
                key={star.value}
                className={`star fa fa-star ${rating >= star.value ? "selected" : ""}`}
                title={star.title}
                onMouseOver={() => handleStarHover(star.value)}
                onClick={() => handleStarClick(star.value, star.message)}
              ></i>
            ))}
          </div>
          <div className="starrate">
            <label>
              <input className="ratevalue" type="hidden" name="rate_value" value={rating} />
            </label>
          </div>
        </div>

        <div className="feedback-tags">
          <div className="tags-container" data-tag-set="1" style={{ display: tagsVisible === 1 ? "block" : "none" }}>
            <div className="question-tag">Why was your experience so bad?</div>
          </div>
          <div className="tags-container" data-tag-set="2" style={{ display: tagsVisible === 2 ? "block" : "none" }}>
            <div className="question-tag">Why was your experience so bad?</div>
          </div>
          <div className="tags-container" data-tag-set="3" style={{ display: tagsVisible === 3 ? "block" : "none" }}>
            <div className="question-tag">Why was your average rating experience?</div>
          </div>
          <div className="tags-container" data-tag-set="4" style={{ display: tagsVisible === 4 ? "block" : "none" }}>
            <div className="question-tag">Why was your experience good?</div>
          </div>
          <div className="tags-container" data-tag-set="5" style={{ display: tagsVisible === 5 ? "block" : "none" }}>
            <div className="make-compliment">
              <div className="compliment-container">
                Give a compliment <i className="far fa-smile-wink"></i>
              </div>
            </div>
          </div>

          <div className="tags-box">
            <input type="text" className="tag form-control" name="comment" placeholder="please enter your review" />
            <input type="hidden" name="product_id" value={productId} />
          </div>
        </div>

        <div className="button-box">
          <input
            type="submit"
            className="done btn btn-warning"
            disabled={!isReviewEnabled}
            value="Add review"
            style={{ display: isReviewEnabled ? "inline-block" : "none" }}
          />
        </div>

        <div className="submited-box">
          <div className="loader"></div>
          <div className="success-message">Thank you!</div>
        </div>
      </div>
    </div>
    </div>
  
  );
};

export default WriteReview;
