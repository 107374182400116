import React from 'react'
import strip from '../Assets/Icon/strip.svg'
function Strip() {
  return (
   
        <div className='strip text-center p-60 '>
          <p className='f-40 f-weight-600 mb-4'>Save Money Using <br></br>
            <strong>Gifzi Card Offers</strong>
          </p>
            <img src={strip} alt='strip' className='img-fluid' />
        </div>
   
  )
}

export default Strip