import React, { useEffect, useState } from 'react';
import working from "../../Assets/Contact/working.png";
import contact from "../../Assets/Contact/contact.png";
import "./Banner.css";
import Commonbtn from "../../Commonbtn/Commonbtn";


function Banner() {
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchContent = async () => {
      const baseUrl = `${process.env.REACT_APP_BASE_API_URL}menu/pageContent?slug=contact-us`;

      try {
        const response = await fetch(baseUrl);
        if (!response.ok) {
          throw new Error('Failed to fetch content');
        }
        const result = await response.json();

        // Access the content from the response
        if (result.data && result.data.content && result.data.content.length > 0) {
          setContent(result.data.content[0].content); // Get the content from the first item
        } else {
          setContent('No content available.'); // Handle case with no content
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchContent();
  }); // Run when slug changes


  return (
    <div className="contact-banner container p-60">
      <div className="row">
        <div className="col-md-6">
        <div dangerouslySetInnerHTML={{ __html: content }} />
          <div className="card">
            <div className=" row ">
              <div className="col-md-8">
                <span className="f-13 grey">Get in touch</span>
                <p className="f-20 f-weight-600 mt-2">
                  Connect with us today and let's start something great
                  together!
                </p>
              </div>
              <div className=" col-md-4 end">
                <Commonbtn Commonbtntext="Contact Now" />
              </div>
            </div>
          </div>

          <figure className="contact-left">
            <img src={contact} height="100%" width="100%" />
          </figure>
        </div>
        <div className="col-md-6">
          <figure className="contact-right">
            <img
              src={working}
              className="contact-"
              height="100%"
              width="100%"
            />
          </figure>
        </div>
      </div>
    </div>
  );
}

export default Banner;
