import React from 'react';
import Forgot from '../Components/Authentication/Forgot/Forgot';




function Forgotpassword() {
  return (
    <div>
<Forgot/>
    </div>
  )
}

export default Forgotpassword