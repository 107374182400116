import React, { useEffect, useState } from 'react';
import Commonbtn from '../../Commonbtn/Commonbtn';
import Outline from '../../Commonbtn/Outline';
import './Banner.css';
import { Link } from 'react-router-dom';

const Banner = () => {
 
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchContent = async () => {
      const baseUrl = `${process.env.REACT_APP_BASE_API_URL}menu/pageContent?slug=about-us`;

      try {
        const response = await fetch(baseUrl);
        if (!response.ok) {
          throw new Error('Failed to fetch content');
        }
        const result = await response.json();

        // Access the content from the response
        if (result.data && result.data.content && result.data.content.length > 0) {
          setContent(result.data.content[0].content); // Get the content from the first item
        } else {
          setContent('No content available.'); // Handle case with no content
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchContent();
  }, []);

  return (
    <div className='about-banner'>
      <div className='container'>
        <h1 className='f-40 banner-t' data-aos="fade-up" data-aos-duration="1000">
          We’re dedicated to making gift cards fun,<br></br> personalized, and effortless to use.
        </h1>
        <p className='f-20 greyf6 banner-s' data-aos="fade-up" data-aos-duration="1500">
           <div dangerouslySetInnerHTML={{ __html: content }} />
        </p>
        

        <div className='d-flex-center'>
          <Link to='/gift-cards'>
          
          <Commonbtn Commonbtntext="Start Now" data-aos="zoom-in" data-aos-duration="1000" />
          </Link>
          <Link to='/contact'>
          <Outline Outlinebtntext="Contact Us" data-aos="zoom-in" data-aos-duration="1500" />
          </Link>
        </div>

        <ul className='d-flex-center tick mt-3'>
          <li className='tick-list' data-aos="fade-left" data-aos-duration="1000">
            <p className='f-14'>Trusted by 16,160 businesses</p>
          </li>
          <li className='tick-list ms-5' data-aos="fade-right" data-aos-duration="1000">
            <p className='f-14'>2,734 Proposals signed</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Banner;
