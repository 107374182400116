import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import amazonImg from "../Assets/amazone.png";
import "./offer.css";
import "../Commonbtn/list.css";
import Input from "../Input/Input";
import Alert from "../Alert/Alert";
import AlertRed from "../Alert/AlertRed";
import { CartContext } from "../../context/CartContext";
import Preloader from "../Preloader/Preloader";
import couponImage from "./image.jpg";

function OfferDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState(1);

  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [ShowError, setShowError] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [price, setPrice] = useState("");
  const [error, setError] = useState("");
  const [selectedDenomination, setSelectedDenomination] = useState(null);
  const [showQtyAlert, setShowQtyAlert] = useState(false); // Alert for exceeding quantity
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const { cartItemCountNav, updateCartCount } = useContext(CartContext);

  useEffect(() => {
    const fetchProductDetail = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}home/offer/detail?id=${id}`
        );
        const data = await response.json();

        if (data && data.status === 200) {
          setProduct(data.data.offer_detail);
          // if (data.data.productDetail[0].price.denominations.length > 0) {
          //   setSelectedDenomination(data.data.productDetail[0].price.denominations[0]);
          //   setPrice(data.data.productDetail[0].price.denominations[0]);
          // }
        } else {
          console.error("Failed to fetch product details");
        }
      } catch (error) {
        console.error("Error fetching product details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetail();
  }, [id]);

  const handlePriceChange = (e) => {
    const value = e.target.value;
    setPrice(value);

    if (value === "" || (value >= 10 && value <= 10000)) {
      setError("");
    } else {
      setError("Price must be between 10 and 10,000.");
    }
  };

  const handleDenominationSelect = (denomination) => {
    setSelectedDenomination(denomination);
    setPrice(denomination);
    setError("");
  };

  const addToCart = async () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      setShowLoginAlert(true);
      setTimeout(() => {
        setShowLoginAlert(false);
        navigate("/login");
      }, 2000);
      return;
    }

    if (error || price === "") {
      setError(
        "Please enter a valid price between 10 and 10,000 before adding to the cart."
      );
      return;
    }

    let newQty = cartItemCountNav + quantity;
    if (newQty > 10) {
      setShowAlert(true);
      setAlertMessage("You cannot add more than 10 items!");
      setTimeout(() => setShowAlert(false), 2000);

      return;
    }

    setIsButtonDisabled(true);

    const newItem = {
      sku: product.sku,
      qty: quantity,
      product_price: parseFloat(price),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}cart/add`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(newItem),
        }
      );

      const data = await response.json();

      if (data.status === 200) {
        setShowSuccessAlert(true);
        updateCartCount(cartItemCountNav + quantity);
        setTimeout(() => {
          setShowSuccessAlert(false);
          navigate("/cart");
        }, 2000);
      } else if (
        data.status === 400 &&
        data.message === "Product already exists in cart."
      ) {
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 2000);
        setIsButtonDisabled(false);
        console.error(data.message);
      } else {
        console.error("Failed to add item to cart:", data.message);
        setIsButtonDisabled(false);
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
      setIsButtonDisabled(false);
    }
  };

  // Function to handle quantity changes
  const handleQuantityChange = (change) => {
    if (quantity + change > 10) {
      setShowQtyAlert(true);
      setTimeout(() => {
        setShowQtyAlert(false);
      }, 2000); // Show alert for 2 seconds
    } else {
      setQuantity((prevQty) => Math.max(prevQty + change, 1)); // Ensure quantity does not go below 1
    }
  };

  if (loading) {
    return <Preloader />;
  }

  if (!product) {
    return <div>Product not found</div>;
  }

  const getThumbnail = (thumbnail) => {
    switch (thumbnail) {
      case "https://gbdev.s3.amazonaws.com/uat/product/CLAIMCODE/d/thumbnail/327_microsite.jpg":
        return "https://www.crafin.in/wp-content/uploads/2024/08/Sell_amazon_shopping_voucher-300x169.png";
      case "https://gbdev.s3.amazonaws.com/uat/product/CNPIN/d/thumbnail/324_microsite.png":
        return "https://www.crafin.in/wp-content/uploads/2019/08/Flipkart-Gift-Cards-300x169.jpg";
      case "https://gbdev.s3.amazonaws.com/uat/product/EGCGBAMZ001/d/thumbnail/135_microsite.jpg":
        return "https://www.crafin.in/wp-content/uploads/2019/03/amazon.png";
      case "https://gbdev.s3.amazonaws.com/uat/product/EGCGBBMS001/d/thumbnail/217_microsite.png":
        return "https://www.crafin.in/wp-content/uploads/2023/06/zomato_vouchers-300x169.jpg";
      case "https://gbdev.s3.amazonaws.com/uat/product/EGCGBFK001/d/thumbnail/66_microsite.jpg":
        return "https://www.crafin.in/wp-content/uploads/2023/06/swiggy-money-gift-cards-300x169.jpeg";
      case "https://gbdev.s3.amazonaws.com/uat/product/EGVGBSHSS001/d/thumbnail/107_microsite.jpg":
        return "https://www.crafin.in/wp-content/uploads/2024/08/Amazon-Fresh-Vouchers-300x169.png";
      default:
        return thumbnail;
    }
  };
  return (
    <div className="productdetail">
      <div className="container my-20">
        <div className="row gap-2">
          {showAlert && <AlertRed title={alertMessage} />}
          <div
            className="product-list p-60"
            style={{
              backgroundImage: `url(${couponImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              // height: "00px",
            }}
          >
            <div className="container">
              <div className="gift-card">
                <div className="row align-items-center">
                  {/* Product Image Section */}
                  <div className="col-md-5 text-center">
                    <div className="img-pro">
                      <img
                        src={getThumbnail(product.thumbnail) || amazonImg}
                        alt={product.name || "Product"}
                        className="rounded"
                        style={{
                          paddingTop: "40px",
                          paddingLeft: "160px",
                          height: "80%",
                          width: "90%",
                        }}
                      />
                    </div>
                  </div>

                  {/* Product Content Section */}
                  <div
                    className="col-md-3 text-left"
                    data-aos="fade-right"
                    data-aos-delay="300"
                  >
                    <div className="d-block border-0 text-left p-2">
                      <div className="d-flex gap-2 mb-1">
                        <p className="expiry-time text-danger f-14 f-weight-600">
                          Expiry: {product.start_date} - {product.end_date}
                        </p>
                      </div>
                      <h3 className="f-24 f-weight-600 my-4">{product.name}</h3>

                      {product.discount_amount && product.discount_max ? (
                        <p className="mt-2 mb-1 f-18 f-weight-600 text-success">
                          Discount: {product.discount_max}% Upto Rs.{" "}
                          {product.discount_amount}/-
                        </p>
                      ) : (
                        <>
                          {product.discount_amount && (
                            <p className="mt-2 mb-1 f-16 f-weight-600 text-success">
                              Discount: Rs.{product.discount_amount}/-
                            </p>
                          )}
                          {product.discount_max && (
                            <p className="mt-2 mb-1 f-16 f-weight-600 text-success">
                              Discount: {product.discount_max}%
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  {/* Coupon Code Section */}
                  <div className="col-md-3 text-center">
                    <div style={{ padding: "10px 50px" }}>
                      <h3 className="f-24 f-weight-600">Coupon Code:</h3>
                    </div>
                    <div>
                      <button
                        className="f-18 border-2 border-gray-500 rounded p-2"
                        style={{
                          fontSize: "18px",
                          padding: "8px 16px",
                          border: "1px solid #6B0C73",
                          background: "#DCDCDC",
                        }}
                      >
                        {product.coupon_code}
                      </button>
                    </div>

                    <div className="py-3">
                      <button className="common-btn" type="submit">
                        Copy
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 text-center">
                  <h2
                    className="f-24 font-bold text-900-[6B0C73]"
                    style={{ color: "#6B0C73" }}
                  >
                    Descreption
                  </h2>

                  <div className="description-text">{product.description}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Alerts */}
        {showLoginAlert && <AlertRed title="Please login first!" />}
        {showSuccessAlert && <Alert title="Item is added to cart!!" />}
        {showQtyAlert && <AlertRed title="You can only add up to 10 items!" />}
        {ShowError && <AlertRed title="Product already exists in cart!" />}
      </div>
    </div>

    

  );
}

export default OfferDetail;

