import React from 'react'
import Review from '../Components/Review/Review'

function MyReview() {
  return (
    <div>
        <Review/>
    </div>
  )
}

export default MyReview