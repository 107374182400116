import React from 'react'
import Earn from '../Components/Write&earn/Earn'

function WriteEarn() {
  return (
    <div>

      <Earn/>
    </div>
  )
}

export default WriteEarn