import React from 'react'
import Askfaq from '../Components/Askfaq/Askfaq'

function Faq() {
  return (
    <div>
        <Askfaq/>
    </div>
  )
}

export default Faq