import React from 'react'
import HowItWorks from '../../Assets/HowItWorks/HowItWorks.png'
import Commonbtn from '../../Commonbtn/Commonbtn'
import Outline from '../../Commonbtn/Outline'
import './Banner.css'
import { Link } from 'react-router-dom'
function Banner() {
  return (
    <div className='how-banner p-60'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <h1 className='f-70 heading'>Learn About How Gifzi Works</h1>
            <p className='f-26'>Gifzi simplifies the gifting process for both individuals and businesses. After registering on the platform, users can purchase the Gifzi Plus Card—a company-owned card—and use it to buy from a vast selection of brand gift cards. These gift cards cover various categories like shopping, entertainment, fashion, travel, and more.</p>
            <div className='d-flex  btn-list'>
              <Link to='/giftplus'>
              <Commonbtn Commonbtntext="Start Now" />
              </Link>
              <Link to='/giftplus'>
              <Outline Outlinebtntext="Activate Your Account"  className=""/>
              </Link>
            </div>
          </div>
          <div className='col-md-6'>
            <div>
              <img src={HowItWorks} alt='' height="100%" width="100%" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Banner