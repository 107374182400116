import React, { useState } from 'react';
import './AddPlusCard.css';
import Input from '../Input/Input';
import pluscard from '../Assets/Icon/pluscard.svg';
import Commonbtn from '../Commonbtn/Commonbtn';
import AlertComponent from './AlertComponent '; // Assuming an alert component

function AddPlusCard() {
  const [cardNumber, setCardNumber] = useState('');
  const [cardPin, setCardPin] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [errors, setErrors] = useState({}); // State to store individual field errors

  // Validate input before making API call
  const validateFields = () => {
    const errors = {};
    if (!cardNumber || cardNumber.length !== 12) {
      errors.cardNumber = 'Card number must be 12 digits';
    }
    if (!cardPin || cardPin.length < 4) {
      errors.cardPin = 'Card PIN must be at least 4 characters';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Function to handle adding the card
  const addCard = async () => {
    setErrorMessage('');
    setShowSuccessAlert(false); // Hide success alert before making the request

    if (!validateFields()) {
      return;
    }

    const baseUrl = `${process.env.REACT_APP_BASE_API_URL}gifzi_plus/link`;

    // Get the token from localStorage
    const token = localStorage.getItem("authToken");

    try {
      const response = await fetch(baseUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token, // Pass the token in the Authorization header
        },
        body: JSON.stringify({
          card_number: cardNumber, // Use the correct body key
          card_pin: cardPin,       // Use the correct body key
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setErrorMessage('');
        setShowSuccessAlert(true); // Show success alert
      } else {
        setErrorMessage(data.message || 'Invalid card number or PIN');
      }
    } catch (error) {
      setErrorMessage('Error adding card. Please try again later.');
    }
  };

  // Handler for form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    addCard(); // Trigger card addition on form submit
  };

  // Handler to close the success alert
  const handleCloseAlert = () => {
    setShowSuccessAlert(false);
  };

  return (
    <div className='balance p-60'>
      <div className='container'>
        <h2 className='f-40 f-weight-600 mb-4'>Add Gifzi Plus Card</h2>
        <div className='row bg-fb shadow-sm rounded-3'>
          <div className='col-md-12 pluscrad'>
            <img
              src={pluscard}
              alt='logo'
              data-aos='fade-right'
              data-aos-delay='500'
              height='100%'
              width='100%'
            />
          </div>

          <div className='col-md-12'>
            <h3 className='f-30 mb-30'>Enter card details to Add Card!</h3>
          </div>

          <div className='col-md-12'>
            <Input
              placeholdertext='Enter card number'
              labeltext='Card Number'
              fortext='CardNumber'
              typetext='text'
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
              maxLength={12} // Set maxLength for card number
              error={errors.cardNumber} // Show error for card number
              name="cardNumber"
            />
          </div>

          <div className='col-md-12'>
            <Input
              placeholdertext='Enter card PIN'
              labeltext='Card Pin'
              fortext='CardPin'
              typetext='password'
              value={cardPin}
              onChange={(e) => setCardPin(e.target.value)}
              error={errors.cardPin} // Show error for card pin
              name="cardPin"
              maxLength={6}
            />
          </div>

          <div className='col-md-12 mt-3'>
            <Commonbtn Commonbtntext='Add Card' onclicktext={handleSubmit} />
          </div>

          {errorMessage && (
            <div className='col-md-12 mt-4'>
              <p className='text-danger'>{errorMessage}</p>
            </div>
          )}

          {showSuccessAlert && (
            <AlertComponent title="Card successfully added!" onClose={handleCloseAlert} />
          )}
        </div>
      </div>
    </div>
  );    
}

export default AddPlusCard;
