
import React from 'react'
import './Alert.css'
import close from '../Assets/close.png'

function AlertRed(props) {
  return (
    <div className='card d-flex alert  alertred shadow-sm'>
      <i>
       
<img src={close} height="30px" width="30px" />

      </i>
<p>{props.title}🥺🥺
</p>
    </div>
  )
}

export default AlertRed