import React from 'react';
import Slider from "react-slick";
import banner2 from "../Assets/banner2.png";
import banner1 from "../Assets/banner3.png";
import "./Banner.css";
import Preloader from '../Preloader/Preloader';

function Banner() {
  const [banners, setBanners] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  // Fetch banners from API
  const fetchProductData = async () => {
    const baseUrl = `${process.env.REACT_APP_BASE_API_URL}home/homeData`;

    try {
      const response = await fetch(baseUrl, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const jsonData = await response.json();

      if (jsonData && jsonData.status === 200) {
        console.log("Response Data:", jsonData);
        setBanners(jsonData.data?.banners || []);
      }
    } catch (err) {
      console.error("Error fetching banners:", err);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchProductData();
  }, []);

  // Slider configuration
  const bannerslider = {
    dots: true,
    infinite: true,
    slidesToShow: 1.02,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  const defaultBanners = [
    { media_url: banner1 }, // Default banner 1
    { media_url: banner2 }, // Default banner 2
  ];

  // Use fetched banners or fallback to default
  const displayedBanners = banners.length > 0 ? banners : defaultBanners;

  return (
    <div className="banner">
      <div className="container">
        {isLoading ? (
          <p>r</p>
        ) : displayedBanners.length === 1 ? (
          // Render a single image if only one banner exists
          <div className="single-banner">
            <img
              src={displayedBanners[0].media_url}
              alt="Single Banner"
              className="slider1"
            />
          </div>
        ) : (
          // Render the slider if there are multiple banners
          <Slider {...bannerslider}>
            {displayedBanners.map((banner, index) => (
              <div key={index}>
                <img
                  src={banner.media_url || banner2}
                  alt={`Banner ${index + 1}`}
                  className="slider1"
                />
              </div>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
}

export default Banner;
