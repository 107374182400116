import React from 'react'
import Form from '../Components/Authentication/Register/Form'


function Register() {
  return (
    <div>
        <Form/>
    </div>
  )
}

export default Register