import React from 'react';
import Product from '../Components/Giftcard/Product/Product';
import PLusBanner from '../Components/Giftcard/PLusBanner';

function Giftcard() {
  const token = localStorage.getItem('authToken'); // Retrieve auth token

  return (
    <div>
      {token && <PLusBanner />} {/* Show banner if user is logged in */}
      <Product />
    </div>
  );
}

export default Giftcard;
