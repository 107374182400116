import React from 'react'
import d1 from '../Assets/d1.svg'
import downloadimg from '../Assets/download-img.png'
// import d from '../Assets/d.png'
import Logo from '../Logo/Logo'
import './Download.css'
function Download() {
  return (
    <div className='download-section'>
      <div className='container bg-fb'>
        <div className='row'>
          <div className='col-md-6'>
            <h3 className='f-55'>Get <strong className='f-weight-600'>GIFZI </strong>App
            </h3>
            <p className='f-28 mt-2'>Connect with us today and let's start something great together!</p>
            <p className='greyf6 f-28 mt-5 mb-2'>Download App From</p>
            <div className="d-flex">
              <img className="download " src={d1} />
            
            </div>
          </div>
          <div className='col-md-6 d-flex-center'>
            <i className='download-icon'>
          <img className="download" src={downloadimg} alt=""  height="100%" width="100%"/>
            </i>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Download