import React from 'react'
import Balance from '../Components/Balance/Balance'

function Mybalance() {
  return (
    <div>
        <Balance/>
    </div>
  )
}

export default Mybalance