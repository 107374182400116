import React from 'react'
import hands from '../../Assets/About/about-banner2.png'
import './Banner.css'

function Hand() {
  return (
    <div>  <div className='hand-img'>
    <img src={hands} className='hand' height="100%" width="100%"/>
    </div></div>
  )
}

export default Hand