import React, { useState, useEffect, useRef, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./Navbar.css";
import "../Commonbtn/Common.css";
import { jwtDecode } from "jwt-decode"; // Import jwtDecode as a named import

import arrowdown from "../Assets/Icon/arrowdown.svg";
import { Link, NavLink, useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import logo from "../Assets/logo.png";
import Outline from "../Commonbtn/Outline";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScrollToTop from "../../Pages/Scrolltotop";
import Sidebar from "./Sidebar";
import bell from "../Assets/bell.svg";
import menu from "../Assets/menu.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import "../../Components/Responsive.css";
import { CartContext } from "../../context/CartContext";

const isTokenValid = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // in seconds
    return decodedToken.exp > currentTime;
  } catch (error) {
    return false; // token is invalid
  }
};


function Navbar({ cartItemCount }) {

  


  const { cartItemCountNav } = useContext(CartContext);
  console.log('cartItemCountNav', cartItemCountNav)
  // const [totalItems, setTotalItems] = useState(0);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const accountDropdownRef = useRef(null);
  const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);
  const token = localStorage.getItem("authToken");
  const isLoggedIn = !!token; // Convert token to boolean
  //const firstName = localStorage.getItem("firstName"); // Retrieve first name
  const navigate = useNavigate(); // Initialize navigate for redirection
  const [totalItems, setTotalItems] = useState(0);
console.log(token, "token12")

const [cartItemCount1, setCartItemCount1] = useState(0);
const [firstName,setfirstName]= useState();


useEffect(() => {
  setCartItemCount1(cartItemCountNav); // Sync local state with context state
}, [cartItemCountNav]);


useEffect(() => {
  const fetchCartData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}cart/count`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setCartItemCount1(data?.data?.totalQuantity || 0);
      } else {
        console.error("Failed to fetch cart count.");
      }
    } catch (error) {
      console.error("Error fetching cart data:", error);
    }
  };
  if (isTokenValid(token)) {
    fetchCartData();
  }
}, [token]);


  useEffect(() => {
    const token = localStorage.getItem("authToken");
 

    const fetchCartItems = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}user/profile`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token, // Include 'Bearer' prefix if required
          },
        });

        const data = await response.json();
        setfirstName(data.data.userDetail.first_name)
        console.log(data,'vv')
     
      } catch (error) {
        console.error("Error fetching cart items:", error);
        
      }
    };

    fetchCartItems();
  }, []);

  useEffect(() => {
    const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    setTotalItems(cartItems.length);
  }, []);
 
  const toggleDropdownAccount = () => {
    setAccountDropdownOpen(!accountDropdownOpen);
  };

  // Function to log out
  const handleLogout = () => {
    localStorage.removeItem("authToken"); 
    localStorage.removeItem("firstName"); 
    navigate("/login"); 
  };

  // Handle scroll event for sticky navbar
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setIsSticky(currentScrollTop > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        accountDropdownRef.current &&
        !accountDropdownRef.current.contains(event.target)
      ) {
        setAccountDropdownOpen(false);
      }
    };

    if (accountDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [accountDropdownOpen]);

  const handleMenuClick = () => {
    setAccountDropdownOpen(false);
  };
 
useEffect(() => {
  const fetchCartItems = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}cart/all`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (response.status === 401) {
      
       
        navigate("/login");  // Redirect to login page
        return;
      }
      // Check if the response message indicates that cards are not available
      if (data && data.message === "Cards not available.") {
        // Refresh the page if cards are not available
        window.location.reload();
      } else if (
        data &&
        data.status === 200 &&
        data.data &&
        Array.isArray(data.data.cartData)
      ) {
        // Set cart items and other state when data is valid
        setTotalItems(data.data.totalItems);
        setApiSuccess(true);
      } else {
        console.error("Failed to fetch cart items:", data.message);
        setApiSuccess(false);
        
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
      setApiSuccess(false);
    }
  };

  fetchCartItems();
}, [token]);


  return (
    <div className="header-main">
      <ScrollToTop />
      <header
        className={`navbar-header user-logined ${isSticky ? "sticky" : ""}`}
      >
        <div className="container">
          <div className="row text-center nav-mob">
            <div className="col-md-2 text-center d-flex-center nav-mob-logo">
              <div className="sidemenu-afterlogin after-login">
                <button
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="menu-login"
                >
                  <img src={menu} alt="menu" height="40px" width="40px" />
                </button>
                <Sidebar
                  isOpen={isDropdownOpen}
                  toggleDropdown={setIsDropdownOpen}
                />
              </div>

              <Link to="/home" className="d-block">
                <img
                  src={logo}
                  className="logo-img"
                  alt="logo"
                  height="60"
                  width="100px"
                />
              </Link>
            </div>
            <div className="col-md-7 navbar justify-content-center d-none d-md-flex">
              <ul className="nav-menu d-flex justify-content-between">
                <li>
                  <NavLink to="/home" end> Home </NavLink>
                </li>
                <li>
                  <NavLink to="/about">About Us</NavLink>
                </li>
                <li>
                  <NavLink to="/gift-cards">Gift Cards</NavLink>
                </li>
                <li>
                  <NavLink to="/giftplus">Gifzi Plus</NavLink>
                </li>
                <li>
                  <NavLink to="/HowItWorks">How It Works</NavLink>
                </li>
              </ul>
            </div>
            {isLoggedIn && firstName ?(
              <div className="col-md-3 d-flex-center nav-mob-login after-login">
                <div className="nav-login-cart d-flex-center">
                  <Link to="/cart" className="cart-link">
                    {/* ({cartItemCount}) */}
                    {cartItemCountNav > 0 && (
        <span className="count-cart">{cartItemCountNav}</span>
      )}

      {/* Only show cartItemCount1 when cartItemCountNav is 0 */}
      {cartItemCountNav === 0 && cartItemCount1 > 0 && (
        <span className="count-cart">{cartItemCount1}</span>
      )}
                   
                    {/* Display cart item count */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 64 64"
                      id="cart"
                    >
                      <g fill="#4C093F">
                        <path d="M48.5 45.7H18.2c-.5 0-.9-.2-1.1-.6-.3-.4-.3-.9-.1-1.3l2.6-6.6L17 12.6H8.6V9.8h9.6c.7 0 1.3.5 1.4 1.2l2.8 26.1c0 .2 0 .4-.1.7l-2 5h28.2v2.9"></path>
                        <path d="m21.3 38.8-.6-2.7 31.9-6.6V18.2h-33v-2.8H54c.8 0 1.4.6 1.4 1.4v13.8c0 .7-.5 1.2-1.1 1.3l-33 6.9M49.9 54c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5 5.5 2.5 5.5 5.5-2.5 5.5-5.5 5.5zm0-8.3c-1.5 0-2.8 1.2-2.8 2.8s1.2 2.8 2.8 2.8 2.8-1.2 2.8-2.8-1.3-2.8-2.8-2.8zm-33 8.3c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5 5.5 2.5 5.5 5.5-2.5 5.5-5.5 5.5zm0-8.3c-1.5 0-2.8 1.2-2.8 2.8s1.2 2.8 2.8 2.8 2.8-1.2 2.8-2.8-1.3-2.8-2.8-2.8z"></path>
                      </g>
                    </svg>
                  </Link>
                
                  <Link to="/notification" className="bell-icon">
                    <img src={bell} alt="bell" height="30px" width="30px" />
                  </Link>
                  <div
                    className="account position-relative"
                    ref={accountDropdownRef}
                  >
                    <button
                      className="outline-btn d-flex-center gap-1 mob-none"
                      onClick={toggleDropdownAccount}
                    >
                      <FontAwesomeIcon icon={faUser} className="f-15" />
                      <span className="f-14">{firstName || "User"}</span>{" "}
                      {/* Display first name */}
                      <img
                        src={arrowdown}
                        height="100%"
                        width="100%"
                        alt="arrow"
                        className={accountDropdownOpen ? "rotate-arrow" : ""} // Add a rotate class when open
                      />
                    </button>
                  
                    {accountDropdownOpen && (
                      <ul className="menu-dropdown shadow-sm text-left profile-dropdown">
                        <li
                          className="menu-dropdown-list"
                          onClick={handleMenuClick}
                        >
                          <Link to="/profile">
                            <FontAwesomeIcon
                              icon={faUser}
                              className="menu-list-icon f-15"
                            />
                            <span className="text-list">Profile</span>
                          </Link>
                        </li>
                        <li
                          className="menu-dropdown-list cursor-pointer"
                          onClick={handleLogout}
                        >
                          <FontAwesomeIcon
                            icon={faSignOutAlt}
                            className="menu-list-icon f-15"
                          />
                          <span className="text-list">Logout</span>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-md-3 d-flex-center nav-mob-login">
                <div className="nav-login-cart d-flex-center">
                  {/* Display cart item count */}
                  {/* <Link to="/cart" className="cart-link">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="cart">
                                            <g fill="#4C093F">
                                                <path d="M48.5 45.7H18.2c-.5 0-.9-.2-1.1-.6-.3-.4-.3-.9-.1-1.3l2.6-6.6L17 12.6H8.6V9.8h9.6c.7 0 1.3.5 1.4 1.2l2.8 26.1c0 .2 0 .4-.1.7l-2 5h28.2v2.9"></path>
                                                <path d="m21.3 38.8-.6-2.7 31.9-6.6V18.2h-33v-2.8H54c.8 0 1.4.6 1.4 1.4v13.8c0 .7-.5 1.2-1.1 1.3l-33 6.9M49.9 54c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5 5.5 2.5 5.5 5.5-2.5 5.5-5.5 5.5zm0-8.3c-1.5 0-2.8 1.2-2.8 2.8s1.2 2.8 2.8 2.8 2.8-1.2 2.8-2.8-1.3-2.8-2.8-2.8zm-33 8.3c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5 5.5 2.5 5.5 5.5-2.5 5.5-5.5 5.5zm0-8.3c-1.5 0-2.8 1.2-2.8 2.8s1.2 2.8 2.8 2.8 2.8-1.2 2.8-2.8-1.3-2.8-2.8-2.8z"></path>
                                            </g>
                                        </svg>
                                    </Link> */}
                 {apiError ? (
                    <Link to="/login">
                     <Outline Outlinebtntext="Login" />
                    </Link>
                  ) : (
                    <Link to="/login">
                     <Outline Outlinebtntext="Login" />
                    </Link>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </header>
    </div>
  );
}

export default Navbar;
