import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // Import the CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faXTwitter, faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons';
import Logo from '../Logo/Logo';
import BackToTop from '../BackToTop/BackToTop';
import axios from 'axios';
import Preloader from '../Preloader/Preloader';


const Footer = () => {

  const [contactInfo, setContactInfo] = useState(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_API_URL}menu/contactUs`)
      .then((response) => {
        if (response.data.status === 200) {
          setContactInfo(response.data.data.contact_us);
        }
      })
      .catch((error) => {
        console.error("Error fetching contact us data:", error);
      });
  }, []);

  if (!contactInfo) {
    return <div> <Preloader /></div>;
  }

  return (
    <footer className="footer p-60">
      <div className='container'>
        <div className='row'>
          <div className='col-md-3'>
            <div className="footer-column" >
              <Logo />
              <p className='subtitle'>
                Discover the power of our secure E Gifting.
              </p>
              
              <ul className='d-flex social-icon-list '>
              {contactInfo.facebook && (
                <li>
                  <a target="_blank" href={contactInfo.facebook} className="facebook social-icon">
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                </li>
              )}
              {contactInfo.linkedin && (
                <li>
                  <a target="_blank" href={contactInfo.linkedin} className="linkdin social-icon">
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </li>
              )}
              {contactInfo.twitter && (
                <li>
                  <a target="_blank" href={contactInfo.twitter} className="twitter social-icon">
                    <FontAwesomeIcon icon={faXTwitter} />
                  </a>
                </li>
              )}
              {contactInfo.instagram && (
                <li>
                  <a target="_blank" href={contactInfo.instagram} className="insta social-icon">
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </li>
              )}
              </ul>
            </div>
          </div>

          <div className='col-md-3'>
            <div className="footer-column">
              <h3 className='logo-text'>Company</h3>
              <ul className="footer-services">
                <li><Link to="/about">About</Link></li>
                <li><Link to="/HowItWorks">How it works</Link></li>
                {/* <li><Link to="/">Career</Link></li> */}
                <li><Link to="/blog">Blog</Link></li>
              </ul>
            </div>
          </div>

          <div className='col-md-3'>
            <div className="footer-column">
              <h3 className='logo-text'>Support</h3>
              <ul className="footer-services">
                <li><Link to="/faq">Faq</Link></li>
                <li><Link to="/term">Terms & Conditions</Link></li>
                <li><Link to="/policy">Privacy Policy</Link></li>
                <li><Link to="/refund">Refund Policy</Link></li>
              </ul>
            </div>
          </div>

          <div className='col-md-3'>
            <div className="footer-column">
              <h3 className='logo-text'>Get In Touch</h3>
              <ul className="footer-services">
                <li><Link to="/contact">Contact Us</Link></li>
                {/* <li><Link to="/">Partner with Us</Link></li> */}
                {/* <li><Link to="/">Schedule a Demo</Link></li> */}
              </ul>
            </div>
          </div>
          <div className='col-md-12 text-center'>
<p className='f-16 p-2 mt-5 copy-right'>
© 2024 Madhyamaheshwar Technology Pvt Ltd | All Rights Reserved
</p>
          </div>
        </div>
        <div>
          <BackToTop/>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
