import React from 'react'
import Otp from '../Components/Authentication/Otp/Otp'

function Verification() {
  return (
    <div>
        <Otp/>
    </div>
  )
}

export default Verification