import React from 'react'
import './terms.css';

function Refund() {
  return (
    <div>    
      <section className='p-60 term'>
      <div >
   

        <article className='container'>
          <h2 className='title'>Return and Refund Policy for Gifzi Plus</h2>

          <p>
            At Gifzi Plus, we strive to provide an exceptional gifting experience by offering flexibility and ease of use. However, we understand that situations may arise where users may need to return or seek a refund for their purchases. This detailed return and refund policy outlines the terms and conditions for both individual and corporate users, covering the various scenarios in which returns or refunds may be applicable. We recommend reading the policy thoroughly to ensure you are familiar with the procedures and eligibility criteria.
          </p>

          <h3>1. Eligibility for Refunds</h3>
          <p>
            Gifzi Plus operates under a digital platform where users purchase eGift cards or the Gifzi Plus Card itself. As a general rule, once an eGift card has been delivered or the Gifzi Plus Card has been used, refunds will not be provided. This is primarily due to the nature of digital products, which cannot be returned in the traditional sense. However, there are certain exceptions where a refund might be considered:
          </p>
          <ul>
            <li><strong>Technical Issues:</strong> If there is a technical problem with the delivery of an eGift card, such as non-receipt by the recipient or the delivery of an incorrect card due to platform error.</li>
            <li><strong>Duplicate Purchase:</strong> In cases where a user accidentally makes a duplicate purchase of the same eGift card.</li>
            <li><strong>Unredeemed Cards:</strong> If the eGift card remains unredeemed and untouched, users may be eligible for a refund after reviewing the case.</li>
          </ul>

          <h3>2. Process for Requesting a Refund</h3>
          <p>
            If you believe you are eligible for a refund, you must follow the steps outlined below:
          </p>
          <ol>
            <li><strong>Contact Customer Support:</strong> Email or use our support system to reach out to our customer service team within 14 days of the purchase date. Provide the details of your transaction, including order number, the type of gift card purchased, and the reason for the refund request.</li>
            <li><strong>Review Process:</strong> Our team will review your request and verify the claim. This process usually takes 3-5 business days. If the refund is related to technical issues, we will attempt to resolve the problem first before proceeding with a refund.</li>
            <li><strong>Refund Confirmation:</strong> If your refund is approved, you will receive a confirmation email, and the amount will be credited back to the original payment method within 7-10 business days, depending on the payment provider.</li>
          </ol>

          <h3>3. Non-Refundable Circumstances</h3>
          <p>
            Certain situations are not eligible for a refund. These include:
          </p>
          <ul>
            <li><strong>Redeemed eGift Cards:</strong> Once a gift card has been redeemed, either fully or partially, it is considered used, and no refund will be processed.</li>
            <li><strong>Expired Cards:</strong> Gift cards that have passed their expiration date are not eligible for refunds. It is the user’s responsibility to keep track of expiration dates.</li>
            <li><strong>User Error:</strong> If the user enters incorrect details (such as an incorrect email address) that results in the wrong recipient receiving the card, no refund will be provided. We recommend double-checking all information before finalizing the purchase.</li>
          </ul>

          <h3>4. Corporate User Refund Policy</h3>
          <p>
            Corporate users, who often purchase Gifzi Plus cards or bulk eGift cards, are subject to the same refund policies as individual users, with a few additional provisions:
          </p>
          <ul>
            <li><strong>Bulk Purchase Errors:</strong> In cases where a bulk order of eGift cards has been incorrectly processed or contains technical errors, we will work closely with the corporate account manager to resolve the issue or provide a refund for any unredeemed cards.</li>
            <li><strong>Unclaimed Cards:</strong> If an eGift card sent to an employee, client, or partner remains unclaimed after a significant amount of time (within 30 days), corporate users can request a refund or reissue of the card to a different recipient.</li>
            <li><strong>Refund Processing:</strong> Corporate refunds are generally handled within 10 business days and may take additional time for larger orders.</li>
          </ul>

          <h3>5. Refunds for Gifzi Plus Card</h3>
          <p>
            The Gifzi Plus Card, as a prepaid and reloadable card, functions similarly to eGift cards. Once the card is purchased and activated, refunds are generally not offered unless there is a technical issue or billing error. Specific rules apply:
          </p>
          <ul>
            <li><strong>Unused Gifzi Plus Cards:</strong> If the card remains unused and unactivated, a refund request can be made within 14 days of purchase.</li>
            <li><strong>Reloadable Balance:</strong> Refunds are not available for any balance reloaded onto the Gifzi Plus Card. Once the card is loaded, it can only be spent, not refunded.</li>
          </ul>

          <h3>6. Exchanges and Reissues</h3>
          <p>
            While refunds may not always be possible, Gifzi Plus offers the option to exchange or reissue certain gift cards in the event of an error. This policy applies under the following conditions:
          </p>
          <ul>
            <li><strong>Wrong Gift Card Type:</strong> If the user accidentally purchases a card for the wrong brand, we may be able to offer an exchange for the correct card, provided the original card is unredeemed.</li>
            <li><strong>Recipient Reissue:</strong> In the case of incorrect recipient details, we can reissue the gift card to the correct email address once verified.</li>
            <li><strong>Exchange Limitations:</strong> Please note that exchanges are limited to certain card types and may be subject to availability within our catalog.</li>
          </ul>

          <h3>7. Refunding to Original Payment Method</h3>
          <p>
            All refunds will be processed back to the original payment method used for the purchase. This includes credit/debit cards, PayPal, or any other payment gateways used during the transaction. We do not offer refunds in cash or checks.
          </p>
          <p>
            For corporate users who made purchases via invoice, the refund will be credited back to the original account once the refund request is approved.
          </p>

          <h3>8. Refund Timelines</h3>
          <p>
            Refund requests will be processed within 7-10 business days after approval.
          </p>
          <p>
            Refunds to international payment methods may take slightly longer due to banking procedures, typically within 10-15 business days.
          </p>
          <p>
            Corporate refunds for bulk orders may take up to 14 business days, depending on the size and complexity of the request.
          </p>

          <h3>9. Customer Responsibility</h3>
          <p>
            Users are responsible for ensuring that their account details, including payment information and recipient details, are accurate at the time of purchase. In cases of user error, Gifzi Plus cannot be held liable for issues that arise from incorrect information provided by the user. Double-check all details before finalizing your purchase to avoid any complications with your order.
          </p>

          <h3>10. Contact Us</h3>
          <p>
            If you have any questions about our return and refund policy, feel free to reach out to our customer support team. We are here to assist you in resolving any issues and ensuring you have a smooth experience with Gifzi Plus.
          </p>
        </article>
      </div>
    </section>
  </div>
  )
}

export default Refund