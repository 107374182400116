import React from 'react'
import PayUComponent from '../Components/PayU/PayUComponent'

function PayU() {
  return (
    <div>
        <PayUComponent/>
    </div>
  )
}

export default PayU