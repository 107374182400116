import React, { useEffect } from 'react';
import CryptoJS from 'crypto-js';
//import { json } from 'react-router-dom';

const PayUForm = () => {
  useEffect(() => {
    // Function to generate the hash
    const generateHash = () => {
      const key = '7mRtIw';
      const salt = 'wZyqoFscepr161WxMPrYSLwrjUzedoG0';
      const txnid = 't6svtqtjRdl4ws';
      const amount = '10';
      const productInfo = 'iPhone,Gifzi';
      const firstname = 'Ashish';
      const email = 'test@gmail.com';

      // Optional User Defined Fields (udf1-udf10)
      const someonedetail = {
        firstname: "test",
        email: "test",
        telephone: "test",
        gift_message: "test"
      }
      const udf1 = JSON.stringify(someonedetail); 
      const udf2 = ''; 
      const udf3 = ''; 
      const udf4 = ''; 
      const udf5 = ''; 
      const udf6 = ''; 
      const udf7 = ''; 
      const udf8 = ''; 
      const udf9 = ''; 
      const udf10 = '';

      // Generating the hash string
      const hashString = `${key}|${txnid}|${amount}|${productInfo}|${firstname}|${email}|${udf1}|${udf2}|${udf3}|${udf4}|${udf5}|${udf6}|${udf7}|${udf8}|${udf9}|${udf10}|${salt}`;
      
      // Hashing the string
      const hash = CryptoJS.SHA512(hashString).toString(CryptoJS.enc.Hex);

      return hash;
    };

    // Automatically submit the form after component renders
    const form = document.getElementById('payuForm');
    const hashField = document.getElementById('hash');
    const hash = generateHash();
    
    // Set the calculated hash to the hidden input field
    hashField.value = hash;

    form.submit();
  }, []);

  // Get the current base URL dynamically
  const surl = `${window.location.origin}/success`; // Example: https://example.com/success
  const furl = `${window.location.origin}/failure`; // Example: https://example.com/failure

  return (
    <div>
      <h2>Redirecting to PayU...</h2>
      <form 
        id="payuForm" 
        action='https://secure.payu.in/_payment' 
        method='post'
        style={{ display: 'none' }}
      >
        <input type="hidden" name="key" value="7mRtIw" />
        <input type="hidden" name="txnid" value="t6svtqtjRdl4ws" />
        <input type="hidden" name="productinfo" value="iPhone,Gifzi" />
        <input type="hidden" name="amount" value="10" />
        <input type="hidden" name="email" value="test@gmail.com" />
        <input type="hidden" name="firstname" value="Ashish" />
        <input type="hidden" name="lastname" value="Kumar" />
        <input type="hidden" name="surl" value={surl} />
        <input type="hidden" name="furl" value={furl} />
        <input type="hidden" name="phone" value="9988776655" />
        <input 
          type="hidden" 
          name="hash" 
          id="hash" 
          value="{hash}" // The hash will be generated dynamically and set here
        />
        <input type="submit" value="submit" />
      </form>
    </div>
  );
};

export default PayUForm;
