import React from 'react';

const AlertComponent = ({ title, message, onClose }) => {
  return (
    <div className="alert alert-success alert-dismissible fade show" role="alert">
      <strong>{title}</strong> {message}
      <button type="button" className="btn-close" onClick={onClose}></button>
    </div>
  );
};

export default AlertComponent;
