import React from 'react';
import Slider from "react-slick";
import sponsor1 from '../Assets/sponsor/sponsor1.png';
import sponsor2 from '../Assets/sponsor/sponsor2.png';
import sponsor3 from '../Assets/sponsor/sponsor3.png';
import sponsor4 from '../Assets/sponsor/sponsor4.png';
import sponsor5 from '../Assets/sponsor/sponsor5.png';
import './Sponsor.css';

function Sponsor() {
    const sponsordata = [
        { sponsorimg: sponsor1 },
        { sponsorimg: sponsor2 },
        { sponsorimg: sponsor3 },
        { sponsorimg: sponsor4 },
        { sponsorimg: sponsor5 },
    ];

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,  // Should be 1 for smooth sliding
        autoplay: false,
        arrows: false,
        responsive: [
            {
                breakpoint: 900, // Adjusting for mobile screens below 900px
                settings: {
                    slidesToShow: 2, // Show 2 sponsor images at a time
                    slidesToScroll: 1,
                    infinite: true,
                    speed: 1000,
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 2000, // Ensure this is set
                }
            }
        ]
    };

    return (
        <div className='sponsor'>
            <Slider {...sliderSettings}>
                {sponsordata.map((item, index) => (
                    <div key={index} className='d-flex-center'>
                        <img src={item.sponsorimg} alt='sponsor' height="100%" width="100%"/>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default Sponsor;
