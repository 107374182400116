import React from 'react'


function Title(props) {
  return (
    <div>
   <h3 className='title'>{props.title}</h3>
        
    </div>
  )
}

export default Title