import React from 'react'
import Title from '../../Title/Title';
import './Whoweare.css';


function Whoweare() {
    const contentdata = [
        {
            contentdes : 'We are dedicated to revolutionizing corporate gifting, providing businesses with the tools they need to strengthen relationships and grow. Our platform allows you to send personalized, bulk gift cards in minutes.'
        },
        {
            contentdes : 'We offer a fully customizable white-label marketplace with over 100+ eGift Cards, empowering both small and large businesses to boost revenue and enhance customer loyalty effortlessly.'
        },
    ]
    return (
        <div className='whoarewe p-60'>
            <div className='container'>
                <Title title="WHO ARE WE" />
                <div className='row'>

              {      contentdata.map((item, index) =>(
                 <div key={index} className='col-md-6'>
                 <div className='content'>
                     <p className='f-20'>{item.contentdes}</p>
                 </div>
             </div>

              )
              )

                    }
                   
                </div>
            </div>
        </div>
    )
}

export default Whoweare