import React from 'react'
import Product from '../Components/ProductByCategory/Product/Product'

function ProductByCategory() {

  

  return (
    <div><Product/></div>
  )
}

export default ProductByCategory