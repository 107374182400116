import React from 'react'
import Offers from '../Components/Offers/Offers'


function AllOffers() {
  return (
    <div>
        <Offers/>
    </div>
  )
}

export default AllOffers