import React from "react";
import "./Content.css";

function Content() {
  return (
    <div className="bg-fb content-plus ">
      <div className="container p-60">
        <h2 className="title">Why Gifzi Plus is Perfect for You</h2>
        <div>
          <div className="timeline-main">
            <div className="timeline-wrap">
              <div className="timeline-card">
                <div className="timeline-card-wrap">
                  <div className="card-head-wrap">
                    <h2 className="timeline-card-head">
                      {" "}
                      1. Versatile Gifting
                    </h2>
                    <h6 className="timeline-card-subhead"></h6>
                  </div>
                  <p className="timeline-card-text">
                    Gifzi Plus offers gift cards across numerous categories,
                    including fashion, travel, entertainment, and beauty,
                    catering to diverse tastes and preferences.
                  </p>
                </div>
              </div>
              <div className="timeline-card">
                <div className="timeline-card-wrap">
                  <div className="card-head-wrap">
                    <h2 className="timeline-card-head">
                      {" "}
                      2. Corporate Gifting Made Simple
                    </h2>
                    <h6 className="timeline-card-subhead"></h6>
                  </div>
                  <p className="timeline-card-text">
                    Businesses can easily reward employees, thank clients, or
                    incentivize partners with customizable, bulk gift cards, all
                    through a seamless platform.
                  </p>
                </div>
              </div>
            </div>
            <div className="timeline-wrap">
              <div className="timeline-card">
                <div className="timeline-card-wrap">
                  <div className="card-head-wrap">
                    <h2 className="timeline-card-head">
                      {" "}
                      3. Personalized Experience
                    </h2>
                    <h6 className="timeline-card-subhead"></h6>
                  </div>
                  <p className="timeline-card-text">
                    Add a personal touch to every gift with custom designs and
                    thoughtful messages, ensuring every card feels unique and
                    special.
                  </p>
                </div>
              </div>
              <div className="timeline-card">
                <div className="timeline-card-wrap">
                  <div className="card-head-wrap">
                    <h2 className="timeline-card-head"> 4. Ease of Use</h2>
                    <h6 className="timeline-card-subhead"></h6>
                  </div>
                  <p className="timeline-card-text">
                    Gifzi Plus is designed with user convenience in mind,
                    allowing quick registration and easy access to a variety of
                    gift cards with just a few clicks.
                  </p>
                </div>
              </div>
            </div>
            <div className="timeline-wrap">
              <div className="timeline-card">
                <div className="timeline-card-wrap">
                  <div className="card-head-wrap">
                    <h2 className="timeline-card-head">
                      {" "}
                      5.	Flexible Payment Options
                    </h2>
                    <h6 className="timeline-card-subhead"></h6>
                  </div>
                  <p className="timeline-card-text">
             

Whether purchasing directly or using your Gifzi Plus Card, the platform offers flexible payment options to suit every need.

                  </p>
                </div>
              </div>
              <div className="timeline-card">
                <div className="timeline-card-wrap">
                  <div className="card-head-wrap">
                    <h2 className="timeline-card-head">6.	Wide Range of Occasions
                    </h2>
                    <h6 className="timeline-card-subhead"></h6>
                  </div>
                  <p className="timeline-card-text">
                  
From birthdays and holidays to thank-yous and business milestones, Gifzi Plus covers every gifting occasion effortlessly.

                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Content;
