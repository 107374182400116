import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import amazonImg from "../Assets/amazone.png";
import "./Product.css";
import "../Commonbtn/list.css";
import Input from "../Input/Input";
import Alert from "../Alert/Alert";
import AlertRed from "../Alert/AlertRed";
import { CartContext } from "../../context/CartContext";
import Preloader from "../Preloader/Preloader";
import ribbon from "../Assets/Ribbon.svg"; // Image imports (not needed since we are using product images from API)
import { FaDownload } from "react-icons/fa";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import { BsPersonHeart } from "react-icons/bs";
import { BiSolidRightArrow } from "react-icons/bi";

function ProductDetail() {
  const { sku } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [ShowError, setShowError] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [price, setPrice] = useState("");
  const [error, setError] = useState("");
  const [selectedDenomination, setSelectedDenomination] = useState(null);
  const [showQtyAlert, setShowQtyAlert] = useState(false); // Alert for exceeding quantity
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const { cartItemCountNav, updateCartCount } = useContext(CartContext);
  const [isOpen, setIsOpen] = useState(null);

  const toggleDropdown = (index) => {
    setIsOpen(isOpen === index ? null : index);
  };

  useEffect(() => {
    const fetchProductDetail = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}product/detail?sku=${sku}`
        );
        const data = await response.json();

        if (data && data.status === 200) {
          setProduct(data.data.productDetail[0]);
          if (data.data.productDetail[0].price.denominations.length > 0) {
            setSelectedDenomination(
              data.data.productDetail[0].price.denominations[0]
            );
            setPrice(data.data.productDetail[0].price.denominations[0]);
          }
        } else {
          console.error("Failed to fetch product details");
        }
      } catch (error) {
        console.error("Error fetching product details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetail();
  }, [sku]);

  const handlePriceChange = (e) => {
    const value = e.target.value;
    setPrice(value);
    if (/^\d*$/.test(value)) {
      const numericValue = parseInt(value, 10);

      // Check if the number is within the valid range
      if ((numericValue >= 10 && numericValue <= 10000) || value === "") {
        setPrice(value); // Update state only for valid input
      }
    }
    if (value === "" || (value >= 10 && value <= 10000)) {
      setError("");
    } else {
      setError("Price must be between 10 and 10,000.");
    }
  };

  const handleDenominationSelect = (denomination) => {
    setSelectedDenomination(denomination);
    setPrice(denomination);
    setError("");
  };

  const addToCart = async () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      setShowLoginAlert(true);
      setTimeout(() => {
        setShowLoginAlert(false);
        navigate("/login");
      }, 2000);
      return;
    }

    if (error || price === "") {
      setError(
        "Please enter a valid price between 10 and 10,000 before adding to the cart."
      );
      return;
    }

    let newQty = cartItemCountNav + quantity;
    if (newQty > 10) {
      setShowAlert(true);
      setAlertMessage("You cannot add more than 10 items!");
      setTimeout(() => setShowAlert(false), 2000);

      return;
    }

    setIsButtonDisabled(true);

    const newItem = {
      sku: product.sku,
      qty: quantity,
      product_price: parseFloat(price),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}cart/add`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(newItem),
        }
      );

      const data = await response.json();

      if (data.status === 200) {
        setShowSuccessAlert(true);
        updateCartCount(cartItemCountNav + quantity);
        setTimeout(() => {
          setShowSuccessAlert(false);
          navigate("/cart");
        }, 2000);
      } else if (
        data.status === 400 &&
        data.message === "Product already exists in cart."
      ) {
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 2000);
        setIsButtonDisabled(false);
        console.error(data.message);
      } else {
        console.error("Failed to add item to cart:", data.message);
        setIsButtonDisabled(false);
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
      setIsButtonDisabled(false);
    }
  };

  // Function to handle quantity changes
  const handleQuantityChange = (change) => {
    if (quantity + change > 10) {
      setShowQtyAlert(true);
      setTimeout(() => {
        setShowQtyAlert(false);
      }, 2000); // Show alert for 2 seconds
    } else {
      setQuantity((prevQty) => Math.max(prevQty + change, 1)); // Ensure quantity does not go below 1
    }
  };

  if (loading) {
    return <Preloader />;
  }

  if (!product) {
    return <div>Product not found</div>;
  }

  const getThumbnail = (thumbnail) => {
    switch (thumbnail) {
      case "https://gbdev.s3.amazonaws.com/uat/product/CLAIMCODE/d/thumbnail/327_microsite.jpg":
        return "https://www.crafin.in/wp-content/uploads/2024/08/Sell_amazon_shopping_voucher-300x169.png";
      case "https://gbdev.s3.amazonaws.com/uat/product/CNPIN/d/thumbnail/324_microsite.png":
        return "https://www.crafin.in/wp-content/uploads/2019/08/Flipkart-Gift-Cards-300x169.jpg";
      case "https://gbdev.s3.amazonaws.com/uat/product/EGCGBAMZ001/d/thumbnail/135_microsite.jpg":
        return "https://www.crafin.in/wp-content/uploads/2019/03/amazon.png";
      case "https://gbdev.s3.amazonaws.com/uat/product/EGCGBBMS001/d/thumbnail/217_microsite.png":
        return "https://www.crafin.in/wp-content/uploads/2023/06/zomato_vouchers-300x169.jpg";
      case "https://gbdev.s3.amazonaws.com/uat/product/EGCGBFK001/d/thumbnail/66_microsite.jpg":
        return "https://www.crafin.in/wp-content/uploads/2023/06/swiggy-money-gift-cards-300x169.jpeg";
      case "https://gbdev.s3.amazonaws.com/uat/product/EGVGBSHSS001/d/thumbnail/107_microsite.jpg":
        return "https://www.crafin.in/wp-content/uploads/2024/08/Amazon-Fresh-Vouchers-300x169.png";
      default:
        return thumbnail;
    }
  };
  return (
    <div className="productdetail">
      <div className="container">
        <div className="row">
          {showAlert && <AlertRed title={alertMessage} />}
          <div className="product-list p-80 text-center">
            <div className="container">
              <div className="gift-card">
                <div className="row">
                  <div className="col-md-4 mt-2">
                    <div className="img-pro position-relative">
                      {product.flat_discount?.value ? (
                        <i className="flat-off position-absolute">
                          <div className="ribbon position-relative">
                            <img src={ribbon} height="100%" width="100%" />
                            <p className="m-0 p-0 flat-text">
                              {" "}
                              {product.flat_discount?.value}% Flat off
                            </p>
                          </div>
                        </i>
                      ) : null}

                      <img
                        src={getThumbnail(product.thumbnail) || amazonImg}
                        alt={product.name || "Product"}
                        className="rounded"
                        height="100%"
                        width="100%"
                      />
                    </div>
                  </div>

                  <div
                    className="col-md-8 text-left"
                    data-aos="fade-right"
                    data-aos-delay="300"
                  >
                    <div className="d-block border-0 text-left ">
                      <div className="d-flex gap-2 mb-1">
                        <p className="expiry-time text-danger f-14 f-weight-600">
                          Expiry {product.expiry}
                        </p>
                      </div>
                      <h3 className="f-24 f-weight-600">{product.name}</h3>
                      <h5 className="f-16 f-weight-600">
                        Brand <strong>{product.brand_name}</strong>
                      </h5>

                      <div className="d-flex flex-wrap gap-2 mt-2">
                        <div className="mt-2 col-md-3">
                          <div
                            className="d-flex justify-content-between items-center bg-gray-300 rounded-2 shadow-[20] p-2"
                            style={{
                              border: "0.2px solid #D3D3D3",
                              backgroundColor: "rgba(246, 240, 250, 0.5)",
                            }}
                          >
                            <div className="d-flex gap-2 items-center">
                              <FaDownload
                                style={{ color: "#568203", fontSize: "16px" }}
                                className="text-2xl"
                              />
                              <h1 className="font-bold text-3xl">
                                Instant Delivery
                              </h1>
                            </div>
                            <div
                              className="cursor-pointer px-1"
                              onClick={() => toggleDropdown(1)}
                            >
                              {isOpen === 1 ? (
                                <FaMinus style={{ color: "grey" }} />
                              ) : (
                                <FaPlus style={{ color: "grey" }} />
                              )}
                            </div>
                          </div>
                        </div>
                        {isOpen === 1 && (
                          <div className="px-4 d-flex gap-1 col-md-12 border-1 bg-white shadow-sm py-3 rounded-2 position-relative z-3">
                            <BiSolidRightArrow
                              style={{ color: "grey", fontSize: "10" }}
                            />
                            <div
                              className="font-bold relative z-3"
                              style={{ fontSize: "10" }}
                            >
                              You can instantly deliver the product details to
                              multiple channels such as email, SMS, and
                              WhatsApp. This ensures a smooth and fast
                              transaction process.
                            </div>
                          </div>
                        )}

                        <div className="mt-2 col-md-5">
                          <div
                            className="d-flex justify-content-between items-center  bg-gray-300 rounded-2 shadow-sm p-2"
                            style={{
                              border: "0.2px solid #D3D3D3",
                              backgroundColor: "rgba(245, 237, 247, 0.5)",
                            }}
                          >
                            <div className="d-flex gap-2 items-center">
                              <FaArrowRightArrowLeft
                                style={{ color: "#CC5500", fontSize: "16" }}
                                className="text-4xl"
                              />
                              <h4>10 days Money-Back Guaranteed</h4>
                            </div>
                            <div
                              className="cursor-pointer px-1"
                              onClick={() => toggleDropdown(2)}
                            >
                              {isOpen === 2 ? (
                                <FaMinus style={{ color: "grey" }} />
                              ) : (
                                <FaPlus style={{ color: "grey" }} />
                              )}
                            </div>
                          </div>
                        </div>
                        {isOpen === 2 && (
                          <div className="px-4 d-flex gap-1 col-md-12 border-1 bg-white shadow-sm py-3 rounded-2 position-relative z-3">
                            <BiSolidRightArrow
                              style={{ color: "grey", fontSize: "10" }}
                            />
                            <p className="font-bold text-lg">
                              Full refund guaranteed if we are unable to resolve
                              any technical issues with sending the card within
                              a few days.
                            </p>
                          </div>
                        )}

                        <div className="mt-1 col-md-4">
                          <div
                            className="d-flex justify-content-between items-center rounded-2 shadow-sm p-2"
                            style={{
                              border: "0.2px solid #D3D3D3",
                              backgroundColor: "rgba(246, 240, 250, 0.5)",
                            }}
                          >
                            <div className="d-flex gap-2 items-center">
                              <BsPersonHeart
                                style={{ color: "#470080", fontSize: "18" }}
                                className="text-8xl"
                              />
                              <p className="font-bold text-3xl">
                                Smooth & Secure Payment
                              </p>
                            </div>
                            <div
                              className="cursor-pointer px-1"
                              onClick={() => toggleDropdown(3)}
                            >
                              {isOpen === 3 ? (
                                <FaMinus style={{ color: "grey" }} />
                              ) : (
                                <FaPlus style={{ color: "grey" }} />
                              )}
                            </div>
                          </div>
                        </div>
                        {isOpen === 3 && (
                          <div className="px-4 d-flex gap-1 col-md-12 border-1 bg-white shadow-sm py-2 rounded-2 position-relative z-3">
                            <p className="font-bold text-xl">
                              The payment process is very quick. Discover the
                              power of our secure [gift].
                            </p>
                          </div>
                        )}
                      </div>

                      <p className="mt-2">Select Price</p>
                      {product.price.denominations?.length > 0 ? (
                        <div className="denomination-tabs mb-3">
                          {product.price.denominations.map((denomination) => (
                            <button
                              key={denomination}
                              className={`btn denomination-btn ${
                                selectedDenomination === denomination
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                handleDenominationSelect(denomination)
                              }
                            >
                              ₹{denomination}
                            </button>
                          ))}
                        </div>
                      ) : (
                        <div className="denomination-tabs mb-1">
                          {[
                            500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500,
                            5000,
                          ].map((customDenomination) => (
                            <button
                              key={customDenomination}
                              className={`btn denomination-btn ${
                                selectedDenomination === customDenomination
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                handleDenominationSelect(customDenomination)
                              }
                            >
                              ₹{customDenomination}
                            </button>
                          ))}
                        </div>
                      )}

                      <div className="pricerange">
                        <Input
                          placeholdertext="Enter your Price Range min 10 to max 10,000"
                          name="price_range"
                          labeltext="Price"
                          fortext="price_range"
                          typetext="text"
                          value={price}
                          onChange={handlePriceChange}
                          maxLength={5}
                          error={error}
                        />
                      </div>

                      <div className="d-flex align-items-end gap-2">
                        <div className="d-flex qty-main gap-2">
                          <div className="text-left">
                            <p className="f-16 mb-2 f-weight-600">Quantity </p>
                            <div className="d-flex align-items-center qty">
                              <button
                                className="btn btn-secondary"
                                onClick={() => handleQuantityChange(-1)}
                              >
                                -
                              </button>
                              <span className="mx-2">{quantity}</span>
                              <button
                                className="btn btn-secondary-plus"
                                onClick={() => handleQuantityChange(1)}
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="ml-4 w-100">
                          <button
                            className="common-btn addtocart"
                            disabled={isButtonDisabled}
                            onClick={addToCart}
                          >
                            {isButtonDisabled ? "Processing..." : "Add To Cart"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {showLoginAlert && <AlertRed title="Please login first!" />}
                  {showSuccessAlert && (
                    <Alert title="Item is adding to cart!!" />
                  )}
                  {showQtyAlert && (
                    <AlertRed title="You can only add up to 10 items!" />
                  )}
                  {ShowError && (
                    <AlertRed title="Product already exists in cart!" />
                  )}

                  {/* desc  */}
                  {/* <div className="col-md-12 description text-left">
                    <h2 className="f-20 mb-3 f-weight-600">Description</h2>
                    <div></div>
                  </div>

                  <div className="col-md-12 Term text-left description">
                    <h2 className="f-20 mb-3 f-weight-600">Terms & Conditions</h2>
                    
                  </div> */}

                  <div
                    class="accordion accordion-flush mt-5"
                    id="accordionFlushExample"
                  >
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="flush-headingOne">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          <h2 className="f-20 f-weight-600">Description</h2>
                        </button>
                      </h2>
                      <div
                        id="flush-collapseOne"
                        class="accordion-collapse collapse"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body text-start">
                          {product.description}
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="flush-headingTwo">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          <h2 className="f-20 f-weight-600">
                            Terms & Conditions
                          </h2>
                        </button>
                      </h2>
                      <div
                        id="flush-collapseTwo"
                        class="accordion-collapse collapse"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body text-start">
                          <div
                            dangerouslySetInnerHTML={{ __html: product.tnc }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* desc  */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetail;
