import React from 'react'
import Banner from '../Components/Contact/Banner/Banner'
import Form from '../Components/Contact/Form/Form'

function Contact() {
  return (
    <div>
        <Banner/>
        <Form/>
    </div>
  )
}

export default Contact