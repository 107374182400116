import React from 'react'
import Myprofile from '../Components/MyProfile/Myprofile'

function Profile() {
  return (
    <div>

        <Myprofile/>
    </div>
  )
}

export default Profile