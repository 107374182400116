import React,{ useEffect } from 'react';
import aboutright from '../Assets/about-right.png';
import './Mission.css';




function Mission() {
    return (
        <div className='mission p-60' >
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6 left' data-aos="fade-up" data-aos-duration="1000" data-aos-delay="0">
                        <h2 className='f-40  text-white mb-30 text-uppercase' > Transforming the gift card experience by making it <strong className='text-yellow'>Fun, Personal, and Effortless.
                            </strong> </h2>
                        <p className='text-20  text-white'>Our platform allows users to create customized gift cards that truly represent the personalities of both the giver and the receiver.
 </p>

                    </div>
                    <div className='col-md-6' data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
                        <img src={aboutright} alt='yellow' height="100%" width="100%" />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Mission