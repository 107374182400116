import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import gifziplus from '../Assets/gifziplus.jpeg'; 
import westside from '../Assets/westside.png'; // Default image if not available in the response
import Commonbtn from '../Commonbtn/Commonbtn';
import './Myorder.css';
import Preloader from '../Preloader/Preloader';

function MyOrderDetail() {
  const { id } = useParams(); // Access the order ID from the URL
  const [orderDetail, setOrderDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("authToken");

  const [selectedVoucherIndex, setSelectedVoucherIndex] = useState(0); 

  useEffect(() => {
    const fetchOrderDetail = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_API_URL}order/detail?id=${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        
        if (response.status === 200 && response.data?.data) {
          setOrderDetail(response.data.data);
          setError(null);
        } else {
          setError('Failed to fetch order details.');
        }
        setLoading(false);
      } catch (err) {
        setError('An error occurred while fetching order details. Please try again later.');
        setLoading(false);
      }
    };

    fetchOrderDetail();
  }, [id, token]);

  if (loading) return <div> <Preloader /></div>;
  if (error) return <div>{error}</div>;

  // Extract the first voucher details if available
  const firstVoucher = orderDetail?.vouchers?.[0] || null;
  const voucherDetails = orderDetail?.vouchers;

  return (
    <div className='p-60 order-detail'>
      <div className='container'>
        <div className='Orderhistorydata-item shadow-sm rounded-3 row mb-3 align-items-center'>
        <div className="Orderhistorydata-icon col-md-3">
  {orderDetail?.vouchers?.[selectedVoucherIndex] ? (
    <img
      src={orderDetail.vouchers[selectedVoucherIndex].thumbnail || westside} // Fallback to 'westside' if 'thumbnail' is missing
      alt="Order"
      height="100%" // Ensures the image takes full height of the container
      width="100%"  // Ensures the image takes full width of the container
    />
  ) : (
    <img
      src={westside} // Default to 'westside' image if no voucher exists
      alt="Default Order"
      height="100%"
      width="100%"
    />
  )}
</div>

          <div className='Orderhistorydata-content col-md-9'>
            <div className='d-flex w-100 gap-2 align-items-center mb-1'>
            <h4 className='f-20 f-weight-600 text-muted '>
              Order id: <b>{orderDetail?.code}</b>
            </h4>
            
            <div className='d-flex btn-list  gap-2'>
            <button 
  className={`status-status btn-lg ${
    orderDetail?.status === 1 
      ? 'success-status' 
      : orderDetail?.status === 2 
      ? 'failed-status' 
      : 'pending-status'
  }`}
>
  Status: { 
    orderDetail.status === 1 ? 'Success' : 
    orderDetail.status === 2 ? 'Failed' : 
    'Pending' 
  }
</button>
            </div>
            </div>
            <p className='text-muted f-16 mt-2'>
              Order date & time: {orderDetail?.order_dt}
            </p>
            <hr />

              <div className='first-voucher-details'>



               
                
                <p className='f-16 text-grey mb-1'>
              Transaction ID: <b>{orderDetail?.online_payment_data?.transaction_id || 'N/A'}</b>
            </p>
            <p className='f-16 mb-2 text-grey f-weight-600 '>
            Receiver Email: {orderDetail?.receiver_email || 'N/A'}
            </p>
            <p className='f-16 mb-2 text-danger f-weight-600'>
              {orderDetail?.reason}
            </p>
                            </div>

         <div className='d-flex gap-2 mt-1'>
         <p className='f-18 f-weight-600 mb-1'>
              Total Amount: Rs. {orderDetail?.total_amount || 'N/A'}
            </p>
            <p>|</p>
            <p className='f-18 f-weight-600 mb-2 text-success'>
              Payment Mode:  {orderDetail?.payment_mode === 1
                    ? "Online"
                    : orderDetail.payment_mode === 0
                    ? "Gifzi Plus"
                    : "Online + Gifzi Plus"}
            </p>
         </div>
          
        
         
         {orderDetail?.is_gifzi_plus_order === 1 && (
  <div className='text-muted'>
    If Gifzi Plus balance is used then the same will be refunded within 3 hours. In case of other payment method, amount will be refunded within 72 hours.
  </div>
)}

            <br/>
          </div>
        </div>
        
        {/* Tabs for Vouchers */}
        <ul className="nav nav-tabs mb-3">
          {orderDetail?.vouchers?.map((voucher, index) => (
            <li className="nav-item" key={index}>
              <button
                className={`nav-link  ${selectedVoucherIndex === index ? 'active' : ''}`}
                onClick={() => setSelectedVoucherIndex(index)}
              >
                Voucher {index + 1}
              </button>
            </li>
          ))}
        </ul>

        {/* Display Selected Voucher Details */}
        {orderDetail?.vouchers?.[selectedVoucherIndex] && (
          <div className='col-md-12'>
            <i className='order-img-icon'>
            <img
              src={orderDetail?.vouchers?.[selectedVoucherIndex]?.thumbnail || westside}
              alt="Order"
              width="100%"
              height="100%"
            />
            </i>
        
            <h4 className='f-18 f-weight-600 mb-1 text-main'>{orderDetail.vouchers[selectedVoucherIndex].product_name}</h4>
            <div className='d-flex gap-1 mb-2'>
              {/* <p className='f-16 mb-1 text-muted f-weight-600'>SKU: {orderDetail.vouchers[selectedVoucherIndex].sku}</p> */}
              {/* <p className='f-17'>|</p> */}
              <p className='f-16 text-danger f-weight-600'>Validity: {orderDetail.vouchers[selectedVoucherIndex].validity}</p>
              <p className='f-17'>|</p>
              <p className='f-16 mb-1 text-muted f-weight-600'>Amount: {orderDetail.vouchers[selectedVoucherIndex].amount}</p>
            </div>
            <p className='f-16 mb-1'>
              Card Number: { 
                orderDetail.vouchers[selectedVoucherIndex].card_number 
                  ? orderDetail.vouchers[selectedVoucherIndex].card_number 
                  : 'null' 
              }

            </p>
            <p className='f-16 mb-1'>
              Card Pin: {orderDetail.vouchers[selectedVoucherIndex].card_pin}
            </p>
            {orderDetail.vouchers[selectedVoucherIndex].tnc?.link && (
              <p className='f-16 mb-1'>
                Please read TnC <a target="_blank" rel="noopener noreferrer" href={orderDetail.vouchers[selectedVoucherIndex].tnc.link}>from here</a>
              </p>
            )}

<div class="accordion mt-3" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
      <h2 className="f-20 f-weight-600 text-main">Terms & Conditions</h2>
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      <div dangerouslySetInnerHTML={{ __html: orderDetail.vouchers[selectedVoucherIndex].tnc?.content || '' }} />      </div>
    </div>
  </div>


</div>

            
          
            
          </div>
        )}


      </div>

      

    </div>
  );
}

export default MyOrderDetail;
