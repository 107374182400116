import React, { useState } from 'react';
import cardd from '../Assets/dhand.png';
import cardd1 from '../Assets/dhand1.png';
import Success from '../Success/Success';
import CryptoJS from "crypto-js";

function Denomination({
  onClose,
  selectedDenomination,
  handleDenominationSelect,
  customAmount,
  handleCustomAmountChange,
  onAddToCart,
}) {
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertClass, setAlertClass] = useState('');
  const [quantity, setQuantity] = useState(1); // Initialize quantity with 1
  const [baseAmount, setBaseAmount] = useState(customAmount || selectedDenomination); // Track base amount
  
  const token = localStorage.getItem("authToken");
  const firstName = localStorage.getItem('firstName');
  const lastName = localStorage.getItem('lastName');
  const userEmail = localStorage.getItem('userEmail');
  const userMobile = localStorage.getItem('userMobile');
  const userId = localStorage.getItem('userId');
  
  const handleSelectDenomination = (denomination) => {
    setBaseAmount(denomination);
    setQuantity(1); // Reset quantity to 1 when selecting a new denomination
    handleDenominationSelect(denomination);
    handleCustomAmountChange({ target: { value: denomination } });
  };

  const handleOrderNow = async () => {
    setLoading(true);
    const key = `${process.env.REACT_APP_PAYU_KEY}`;
    const salt = `${process.env.REACT_APP_PAYU_SALT}`;
    const generateTxnId = () => `${Math.random().toString(36).substr(2, 8)}${Date.now()}`;
    
    const txnid = generateTxnId();
    const amount = baseAmount * quantity;
    const productInfo = 'gifzi_plus_' + userId;
    const firstname = firstName;
    const email = 'test@gmail.com';
    const udf1 = quantity, udf2 = '', udf3 = '', udf4 = '', udf5 = '', udf6 = '', udf7 = '', udf8 = '', udf9 = '', udf10 = '';
    const hashString = `${key}|${txnid}|${amount}|${productInfo}|${firstname}|${email}|${udf1}|${udf2}|${udf3}|${udf4}|${udf5}|${udf6}|${udf7}|${udf8}|${udf9}|${udf10}|${salt}`;
    const hash = CryptoJS.SHA512(hashString).toString(CryptoJS.enc.Hex);

    const form = document.createElement('form');
    form.action = `${process.env.REACT_APP_PAYU_URL}`;
    form.method = 'POST';
    const paymentmode = `${process.env.REACT_APP_PAYU_PAYMENTMODE}`;
    
    const fields = [
      { name: "key", value: key },
      { name: "txnid", value: txnid },
      { name: "productinfo", value: productInfo },
      { name: "amount", value: amount },
      { name: "email", value: email },
      { name: "firstname", value: firstname },
      { name: "lastname", value: lastName },
      { name: "paymentmode", value: paymentmode },
      { name: "surl", value: `${process.env.REACT_APP_PAYU_SUCCESS_URL}` },
      { name: "furl", value: `${process.env.REACT_APP_PAYU_FAILURE_URL}` },
      { name: "phone", value: userMobile },
      { name: "udf1", value: udf1 }, // Pass udf1
      { name: "hash", value: hash }
    ];

    fields.forEach(({ name, value }) => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = name;
      input.value = value;
      form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();
  };

  const incrementQuantity = () => {
    setQuantity((prevQuantity) => {
      const newQuantity = Math.min(prevQuantity + 1, 5);
      // handleCustomAmountChange({ target: { value: baseAmount * newQuantity } });
      return newQuantity;
    });
  };

  const decrementQuantity = () => {
    setQuantity((prevQuantity) => {
      const newQuantity = Math.max(prevQuantity - 1, 1);
      // handleCustomAmountChange({ target: { value: baseAmount * newQuantity } });
      return newQuantity;
    });
  };

  return (
    <div className="popup">
      <div className="modal-overlay">
        <div className="modal-content">
          <span className="close-modal-btn shadow-sm" onClick={onClose}>×</span>
          <i className="d-img">
            <img src={cardd} height="100%" width="100%" alt="Denomination Card" />
          </i>
          <i className="d-img left">
            <img src={cardd1} height="100%" width="100%" alt="Denomination Card" />
          </i>
          <h4 className="f-28 f-weight-600 mb-4">Select Denomination or <br /> Enter Custom Amount</h4>
          <div className="denominations-list d-flex-center gap-2 mt-2 flex-wrap mt-3 f-14">
            {["10", "100", "1000", "10000", "25000", "50000", "100000"].map((denomination) => (
              <button
                key={denomination}
                className={`denomination-btn ${customAmount === denomination ? 'active' : ''}`}
                onClick={() => handleSelectDenomination(denomination)}
              >
                {denomination}
              </button>
            ))}
          </div>
          <div className="custom-amount-input mt-3">
            <input
              type="number"
              placeholder="Enter custom amount (10 - 100000)"
              value={customAmount}
              onChange={handleCustomAmountChange}
              min="10"
              max="100000"
            />
          </div>

          {/* Quantity Selector */}
         <div className='mx-5 d-flex align-items-start'>
         <div className="d-flex align-items-center qty mt-3 ">
            <button className="btn btn-secondary" onClick={decrementQuantity}>-</button>
            <span className="mx-2">{quantity}</span>
            <button className="btn btn-secondary-plus" onClick={incrementQuantity}>+</button>
          </div>
         </div>

          {alertMessage && (
            <div className={`alert ${alertClass}`}>{alertMessage}</div>
          )}

          <div className="mt-5">
            <Success onClick={handleOrderNow} buttonText={loading ? 'Processing...' : 'Order Now'} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Denomination;
