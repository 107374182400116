import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpLong } from '@fortawesome/free-solid-svg-icons';

function Outline(props) {
  return (
    <div>
      <button className='outline-btn d-flex gap-2'> {props.Outlinebtntext} 
      <svg width="14  " height="15" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 1L16 16ZM16 16V1.6ZM16 16H1.6Z" fill="#4C093F"/>
<path d="M1 1L16 16M16 16V1.6M16 16H1.6" stroke="#4C093F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

</button>
    </div>
  )
}

export default Outline