import React from 'react';
import { useNavigate } from 'react-router-dom';

function ArrowRedirect() {
  const navigate = useNavigate();

  const handleArrowClick = () => {
    if (window.history.length > 2) {
      // Go to the previous page if there's browser history
      navigate(-1);
    } else {
      // Otherwise, navigate to the dashboard page
      navigate('/home');
    }
  };

  return (
    <i className="arrow-left rotate" onClick={handleArrowClick}>
  <svg
    width="18px"
    height="15px"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_945_2412)">
      <path
        d="M17 7.5H1M1 7.5L7 13.5M1 7.5L7 1.5"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_945_2412">
        <rect
          width="18"
          height="14"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
</i>

  );
}

export default ArrowRedirect;
