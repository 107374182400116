import React from "react";
import FetchContent from "./FetchContent"; // Adjust the import path as needed

function PrivacyPolicy() {
  return (
    <div className="p-60 term">
      <div className="container">
        <FetchContent slug="privacy-policy" />{" "}
        {/* Pass the slug for the privacy policy */}
      </div>
    </div>
  );
}

export default PrivacyPolicy;
