import React, { useState } from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Searchbar.css';

const SearchBar = ({ searchtext, searchtype, searchclass, onSearch }) => {
  const [query, setQuery] = useState('');

  const handleChange = (e) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
    onSearch(newQuery); // Call onSearch every time the input value changes
  };

  const searchclassName = `search-bar-container ${searchclass || ''}`.trim();

  return (
    <div className={searchclassName}>
      <input
        type={searchtype}
        placeholder={searchtext}
        value={query}
        onChange={handleChange} // Call handleChange on input change
        className='search-input'
      />
      <button className="search-button">
        <FontAwesomeIcon icon={faSearch} className='f-16 greyf6' />
      </button>
    </div>
  );
};

export default SearchBar;
