import React, { useContext, useEffect, useState } from 'react';
import './Successfull_Page.css';
import { Link, useLocation } from 'react-router-dom';
import axios from "axios";
import { CartContext } from '../../context/CartContext';

const Successfull_Page = () => {
  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  const location = useLocation();
  const [transactionId, setTransactionId] = useState(null);
  const [isGift, setIsGift] = useState(false);
  const [giftDetail, setGiftDetail] = useState({
    firstname: "",
    email: "",
    telephone: "",
    gift_message: "",
  });

  const { cartItemCountNav, updateCartCount } = useContext(CartContext);


  useEffect(() => {
   updateCartCount(0); // Increment the count by 1
 }, [cartItemCountNav, updateCartCount]);

  useEffect(() => {
    const storedGiftDetail = localStorage.getItem("giftDetails");
    if (storedGiftDetail) {
      const parsedGiftDetail = JSON.parse(storedGiftDetail);
      const hasGiftData = Object.values(parsedGiftDetail).some(value => value.trim() !== "");
      setGiftDetail(parsedGiftDetail);
      setIsGift(hasGiftData);
    } else {
      setIsGift(false);
    }
  }, []);
console.log(localStorage.getItem("giftDetails"))
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('transcationid');
    setTransactionId(id);
  }, [location.search]);


  useEffect(()=>{
    handleApiCall();
  },[transactionId]);


  const handleApiCall = async () => {
    if (!transactionId) {
      console.log("No transaction ID found. Skipping API call.");
      updateCartCount(0); // Always reset cart count
      console.log("cartIdddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddunt.", cartItemCountNav);
      return;
    }
    console.log("Starting API call for transaction ID:", transactionId);
    const apiUrl = `${baseUrl}payu/detail?txn_id=${transactionId}`;
  
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${localStorage.getItem('authToken')}`,
        },
      });
  
      if (response.ok) {
        const result = await response.json();
        const paymentData = result?.data?.payment_data;
  
        if (paymentData?.status === "success") {
          console.log("Transaction successful:", paymentData);
  
          // Prepare data for checkout API
          const productInfo = paymentData.productinfo;
          if (productInfo.includes("both")) {
            const udf2 = paymentData.udf2;
            const decodedData = decodeHTMLEntities(udf2);
            const cardData = JSON.parse(decodedData);
  
            const storedGiftDetails = localStorage.getItem("giftDetails");
            const parsedGiftDetails = storedGiftDetails ? JSON.parse(storedGiftDetails) : null;
  
            // Check if gift details are present
            const hasGiftDetails = parsedGiftDetails && Object.values(parsedGiftDetails).some(value => value.trim() !== "");
  
            const paymentDetails = {
              payment_mode: 2,
              status: 1,
              is_gift: hasGiftDetails ? 1 : 0,
              ...(hasGiftDetails && { gift_detail: parsedGiftDetails }),
              online_payment_detail: {
                transaction_id: transactionId,
                amount: paymentData.amount,
              },
              card_payment_detail: cardData.map((card) => ({
                id: card.id,
                link_id: card.link_id,
                amount: card.balance || 0,
              })),
            };
  
            await checkoutPayment(paymentDetails);
          }
        } else {
          console.error("Transaction failed or status is not 'success':", paymentData?.status);
        }
      } else {
        const errorData = await response.json();
        console.error('Error during transaction API call:', errorData.message);
      }
    } catch (error) {
      console.error('API call error:', error.message || error);
    }
  };
  
  // Helper function to handle checkout API
  const checkoutPayment = async (paymentDetails) => {
    try {
      const apiResponse = await axios.post(
        `${baseUrl}order/checkout`,
        paymentDetails,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem('authToken'),
          },
        }
      );
  
      if (apiResponse.status === 200) {
        console.log("Checkout successful");
        localStorage.removeItem("giftDetails");
      } else {
        console.error("Error during checkout:", apiResponse.data);
      }
    } catch (error) {
      console.error("Checkout API Error:", error.response?.data || error.message);
    }
  };
  
  

  function decodeHTMLEntities(text) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  }

  return (
    <div className='successfullpage'>
      <div className="js-container container">
        <div className="celebration-content">
          <div className="checkmark-circle">
            <div className="background"></div>
            <div className="checkmark draw"></div>
          </div>
          <h1 className='mb-2 f-30 f-weight-600 text-success mt-3'>Payment Success!</h1>
          <p className='f-18 text-muted mb-4 '>Your Payment is now confirmed. For order status Please go throught in My Order </p>
          <Link to="/gift-cards">
            <button className="common-btn">
              Back
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Successfull_Page;
