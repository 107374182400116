import React from 'react'
import Commonbtn from '../../Commonbtn/Commonbtn'
import doddle from '../../Assets/HowItWorks/doddle.png'
import spring from '../../Assets/HowItWorks/spring.svg'
import './User.css'

function User() {
  return (
    <div className='user p-60'>
        <div className='container'>
            <div className='row'>
<div className='col-md-6 content corporate-content'>
<h3 className='f-70 heading'>For  Corporate user</h3>
<p className='greyf6 f-24 subtext'>Gifzi simplifies corporate gifting. After registering, businesses can access the Gifzi Plus Card, allowing them to bulk purchase gift cards across various categories like fashion, travel, and entertainment. Companies can reward employees, thank clients, and incentivize partners with customized, thoughtful gift cards that can be tailored for any occasion. Gifzi’s seamless platform and wide selection make it the perfect solution for strengthening business relationships and expressing appreciation.</p>

<Commonbtn Commonbtntext="Start Now" />
</div>
<div className='col-md-6 image-doddle'>
    <img src={doddle} alt="" className=''  height="100" width="100"/>
</div>
<div className='col-md-6 image-spring'>

    <img src={spring} alt="" className='' height="100" width="100" />
</div>
<div className='col-md-6 content  customer-content text-end'>
<h3 className='f-70 heading'>For General Users</h3>
<p className='grey25 f-24 subtext'> Gifzi offers an easy way to personalize gift-giving. After signing up, individuals can purchase the Gifzi Plus Card or directly select gift cards from popular brands across categories like shopping, beauty, sports, and travel. Whether gifting family or friends, Gifzi allows users to celebrate birthdays, holidays.</p>

<Commonbtn Commonbtntext="Start Now" />
</div>
            </div>

        </div>

    </div>
  )
}

export default User