import React from 'react';
import FetchContent from './FetchContent'; // Adjust the path according to your project structure
import './terms.css';

function TermsAndConditions() {
  return (
    <div>
      <section className='p-60 term'>
        <div className='container'>
          <h2 className='title'>Terms & Conditions</h2>
          <FetchContent slug="terms-conditions" />
        </div>
      </section>
    </div>
  );
}

export default TermsAndConditions;
