import { createContext, useState, useEffect } from 'react';
import { getToken, messaging} from '../firebase';


export const FCMContext = createContext();

export const FCMProvider = ({ children }) => {
  const [fcmToken, setFcmToken] = useState(null);

  useEffect(() => {
    const requestNotificationPermission = async () => {
      try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          console.log('Notification permission granted.');
          const VAPID_KEY = 'BL2hCDlpJVE3zxMxZaZY-XlKNDZgN3pqF_iQ3Tz8US7p0KEv_o0hTAuD8IbycQt6o7-vaAbYBTu_xkfXnF1xg9E';
          const currentToken = await getToken(messaging, { vapidKey: VAPID_KEY });
          console.log(currentToken,"fcm")
          if (currentToken) {
            setFcmToken(currentToken); // Set the token in state
          } else {
            console.log('No registration token available.');
          }
        } else {
          console.log('Unable to get permission to notify.');
        }
      } catch (error) {
        console.error('An error occurred while retrieving token.', error);
      }
    };

    requestNotificationPermission();
  }, []);

  return (
    <FCMContext.Provider value={fcmToken}>
      {children}
    </FCMContext.Provider>
  );
};
