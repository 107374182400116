// src/firebase.js
import { initializeApp } from "firebase/app";
//import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getToken, onMessage, getMessaging } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyCjItV52wfVPzzhagcU71URMBXYwni_EiQ",
    authDomain: "gifzi-8a518.firebaseapp.com",
    projectId: "gifzi-8a518",
    storageBucket: "gifzi-8a518.appspot.com",
    messagingSenderId: "885301591795",
    appId: "1:885301591795:web:56059c43c388086e608186",
    measurementId: "G-0MB70LXBVS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging, getToken, onMessage };