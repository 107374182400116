import React, { useState } from 'react';
import './Product/Product.css';
import Slider from "react-slick";
import dinning from '../Assets/Category/dinning.svg';
import living from '../Assets/Category/living.svg';
import fashion from '../Assets/Category/fashion.svg';
import health from '../Assets/Category/health.svg';
import travel from '../Assets/Category/travel.svg';
import electronic from '../Assets/Category/electronic.svg';
import entertainment from '../Assets/Category/entertainment.svg';
import Gift from '../Assets/Category/gift1.svg';
import j from '../Assets/Category/j.svg';
import CategoryTab from './CategoryTab';

// Custom arrow components
const NextArrow = (props) => {
  const { onClick } = props;
  return <div className="slick-next" onClick={onClick}>&#10095;</div>;
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return <div className="slick-prev" onClick={onClick}>&#10094;</div>;
};

function CategoryList() {
  // Category data with heading, description, and image
  const categories = {
    All: { heading: 'All Categories', description: 'Browse all categories', img: Gift },
    dining: { heading: 'Dining & Food', description: 'Explore dining options', img: dinning },
    fashion: { heading: 'Fashion & Beauty', description: 'Latest fashion trends', img: fashion },
    electronic: { heading: 'Electronics', description: 'Best electronics deals', img: electronic },
    entertainment: { heading: 'Entertainment', description: 'Fun and entertainment', img: entertainment },
    health: { heading: 'Wellness & Health', description: 'Health and wellness products', img: health },
    travel: { heading: 'Travel', description: 'Discover new destinations', img: travel },
    jewelry: { heading: 'Jewelry', description: 'Elegant jewelry collections', img: j },
    living: { heading: 'Home & Living', description: 'Home and living products', img: living }
  };

  const [activeCategory, setActiveCategory] = useState('All');

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };

  const categoryslider = {
    dots: false,
    arrows: true,
    infinite: false,
    loop: true,
    slidesToShow: 6,
    slidesToScroll: 3,
    autoplay: false,
    speed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 4, slidesToScroll: 2 } },
      { breakpoint: 768, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 480, settings: { slidesToShow: 3, slidesToScroll: 1 } }
    ]
  };

  return (
    <div className='p-30 container'>
      <h3 className='title'>Category</h3>

      <Slider {...categoryslider}>
        {Object.keys(categories).map((key) => (
          <div className='main-tab' key={key}>
            <div
              className={`tab-list d-flex-center ${activeCategory === key ? 'active' : ''}`}
              onClick={() => handleCategoryClick(key)}
            >
              <i className='icon-cat'>
                <img className="rounded" src={categories[key].img} alt={key} height="100px" width="100px" />
              </i>
              <p className='f-14 f-weight-600'>{categories[key].heading}</p>
            </div>
          </div>
        ))}
      </Slider>

      {/* Pass the selected category's details to the CategoryTab component */}
      <CategoryTab 
        heading={categories[activeCategory].heading} 
        description={categories[activeCategory].description}
        img={categories[activeCategory].img}
      />
    </div>
  );
}

export default CategoryList;
