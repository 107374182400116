import React from 'react'
import MyCart from '../Components/MyCart/MyCart'
import Strip from '../Components/MyCart/Strip'
function Cart() {
  return (
    <div>
<MyCart/>
{/* <Strip/> */}
    </div>
  )
}

export default Cart