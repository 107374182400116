import React from 'react'
import logo from '../Assets/logo.png'
function Logo() {
  return (
    <div className='logocomponent'>
          <img src={logo} alt='logo' height="100px" width="150px"/>
    </div>
  )
}

export default Logo