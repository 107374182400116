import React, { useState } from 'react';
import Logo from '../../Logo/Logo';
import './Forgot.css';
import Commonbtn from '../../Commonbtn/Commonbtn';
import { Link, useNavigate } from 'react-router-dom';
import Input from '../../Input/Input';

function Forgot() {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);
        const baseUrl = `${process.env.REACT_APP_BASE_API_URL}user/password/forget`;

        try {
            const response = await fetch(baseUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({ mobile: phoneNumber }),
            });

            const result = await response.json();

            if (response.ok) {
                // Navigate to OTP verification page
                navigate('/forgetpasswordotp', { state: { mobile: phoneNumber } });
            } else {
                setError(result.message || 'Failed to send OTP. Please try again.');
            }
        } catch (error) {
            setError('An error occurred. Please try again.');
        }

        setLoading(false);
    };

    return (
        <section className='authentication otp card'>
            <div className=''>
                <Logo />
                <h1 className='f-40 mb-3 mt-2'>
                    Forgot password
                </h1>
                <p className='f-18 mb-4 greyf6'>
                    Enter your phone number and we'll send you an OTP to get back into your account.
                </p>

                <form onSubmit={handleSubmit}>
                    <Input
                        placeholdertext="Enter your Phone Number"
                        labeltext="Phone Number"
                        fortext="Phone Number"
                        typetext="text"
                        maxLength={10} // Restrict input to 10 digits
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    {error && <p className='error-message'>{error}</p>}
                    <div className="col-md-12 d-flex-center btn-form">
                        <Commonbtn Commonbtntext={loading ? 'Sending OTP...' : 'Send OTP'} disabled={loading} />
                    </div>
                </form>

                <p className='f-16 greyf6 text-center mt-3'>
                    Back to
                    <Link to='/login'>
                        <strong> Login</strong>
                    </Link>
                </p>
            </div>
        </section>
    );
}

export default Forgot;
