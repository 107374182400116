import React from 'react'
import Commonbtn from '../Commonbtn/Commonbtn';
import './Category.css';
import amazonImg from '../Assets/amazone.png';
import { Link } from 'react-router-dom';

function CategoryDetail() {
  return (
    <div>
            <div className='product-list p-80 text-center bg-fb' >
            <div className='container'>
               
                <div className='row'>
                <h3 className='title'>Categories List</h3>
                 
                        <div  className='col-md-4 gift-card' data-aos="fade-right" data-aos-delay="300">
                            <Link to='/' className='card d-block border-0'>
                                <h3 className='product-name'> Westside Gift Cards</h3>
                                {/* <p className='sub-title pb-2'> {item.detail}</p> */}
                                <img style={{ boxShadow:"0px 1px 8px 1px #473594" }} src={amazonImg} alt='image' className=' rounded' />
                                <h3 style={{ fontFamily:'Poppins', fontWeight:400, fontSize:"1rem", marginTop:"0.8rem", color:"#666666" }} >Westside Gift Cards</h3>
                                <Link to='/' className='f-16 buy-text d-block'>
                                    BUY NOW AT RS.2000
                                </Link>
                            </Link>
                        </div>
                 

                </div>    </div>

        </div>
    </div>
  )
}

export default CategoryDetail