import React from 'react'
import './Alert.css'

function Alert(props) {
  return (
    <div className='card d-flex alert shadow-sm'>
      <i>
       
<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.25 18.75L20.0625 9.9375L18.3125 8.1875L11.25 15.25L7.6875 11.6875L5.9375 13.4375L11.25 18.75ZM13 25.5C11.2708 25.5 9.64583 25.1717 8.125 24.515C6.60417 23.8583 5.28125 22.9679 4.15625 21.8438C3.03125 20.7188 2.14083 19.3958 1.485 17.875C0.829167 16.3542 0.500833 14.7292 0.5 13C0.5 11.2708 0.828333 9.64583 1.485 8.125C2.14167 6.60417 3.03208 5.28125 4.15625 4.15625C5.28125 3.03125 6.60417 2.14083 8.125 1.485C9.64583 0.829167 11.2708 0.500833 13 0.5C14.7292 0.5 16.3542 0.828333 17.875 1.485C19.3958 2.14167 20.7188 3.03208 21.8438 4.15625C22.9688 5.28125 23.8596 6.60417 24.5163 8.125C25.1729 9.64583 25.5008 11.2708 25.5 13C25.5 14.7292 25.1717 16.3542 24.515 17.875C23.8583 19.3958 22.9679 20.7188 21.8438 21.8438C20.7188 22.9688 19.3958 23.8596 17.875 24.5162C16.3542 25.1729 14.7292 25.5008 13 25.5Z" fill="#23B000"/>
</svg>

      </i>
<p>
  {props.title}
</p>
    </div>
  )
}

export default Alert