import React, { useEffect, useState } from "react";
import "./Blog.css";
import { Link } from "react-router-dom";

function List() {
  const [blogData, setBlogData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  

  useEffect(() => {
    const fetchBlogData = async () => {
      const baseUrl = `${process.env.REACT_APP_BASE_API_URL}menu/blogs?offset=0&limit=10`;
      console.log("Base API URL: ", process.env.REACT_APP_BASE_API_URL);


      try {
        const response = await fetch(baseUrl, {
          method: "GET",
        });

        const result = await response.json();
        console.log("aaaaaaaaaaaaaa",result);
        if (response.ok) {
          setBlogData(result.data.blogs);
        } else {
          setError(result.message || "Failed to fetch blogs");
        }
      } catch (err) {
        setError("Something went wrong, please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchBlogData();
  }, []);

  return (
    <div className="p-60 ml-14-d userdetail">
      <div className="container">
        <div className="main-bg-off-white bg-off-white">
          <section className="">
            <div className="container">
              <div className="row">
                <div className="col-md-12 pb-20">
                  <h1 className="title">Blog</h1>
                </div>

                {loading && <p>Loading blogs...</p>}
                {error && <p className="text-danger">{error}</p>}
                {!loading && !error && blogData.map((blog) => (
                  <div key={blog.id} className="col-md-4 d-flex">
                    <Link to={`/blogdetail/${blog.id}`} className="faq-card-link w-100"> 

                      <div className="faq-card bg-white w-100">
                        <i className="blog-img">
                          <img
                            className="rounded"
                            src={blog.image ? blog.image : '/path/to/default/image.png'}
                            alt="blog"
                            height="100%"
                            width="100%"
                          />
                        </i>
                        <div className="py-3">
                          <p className="f-14 grey-85 py-1">
                            Published on: {blog.publised_on}
                          </p>
                          <p className="f-16 text-dark bold-text py-1">{blog.title}</p>
                          <p
                            className="f-14 grey-85 py-1 display-webkit"
                            dangerouslySetInnerHTML={{ __html: blog.description }}
                          ></p>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default List;
