import React from 'react'
import Form from '../Components/Authentication/Signin/Form.js'

function Login() {
  return (
    <div>
    <Form/>
    </div>
  )
}

export default Login