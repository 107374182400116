import React, { useState } from "react";
import Logo from "../../Logo/Logo";
import "./Setpassword.css";
import Commonbtn from "../../Commonbtn/Commonbtn";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Password from "../../Input/Password";

function Setpassword() {
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const mobile = location.state?.mobile || "+91 - 8888888888";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    // Validate passwords
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match.");
      setLoading(false);
      return;
    }
    
    const baseUrl = `${process.env.REACT_APP_BASE_API_URL}user/password/reset`;

    try {
      const response = await fetch(baseUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ mobile, password: formData.password }),
      });

      const result = await response.json();

      if (response.ok) {
        navigate("/login");
      } else {
        setError(result.message || "Failed to reset password. Please try again.");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
    }

    setLoading(false);
  };

  const handleInputPasswordChange = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      password: value,
    }));

    const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)[\S]{8,16}$/;
    if (value.length > 0 && !passwordPattern.test(value)) {
      setError("Password must be 8-16 characters long and contain both letters and numbers.");
    } else {
      setError("");
    }
  };

  const handleInputConfirmPasswordChange = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      confirmPassword: value,
    }));

    if (value !== formData.password) {
      setConfirmPasswordError("Passwords do not match.");
    } else {
      setConfirmPasswordError("");
    }
  };

  return (
    <section className="authentication otp card">
      <div className="">
        <Logo />
        <h1 className="f-40 mb-3 mt-2">Set a new password</h1>
        <p className="f-18 mb-4 greyf6">
          Create a new password. Ensure it differs from previous ones for security.
        </p>

        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-12">
              <Password
                placeholdertext1="Enter your Password"
                labeltext1="Password"
                fortext1="Password"
                typetext1="Password"
                value={formData.password}
                onChange={handleInputPasswordChange}
              />
            </div>
            <div className="col-md-12">
              <Password
                placeholdertext1="Re-enter password"
                labeltext1="Confirm Password"
                fortext1="Password"
                typetext1="Password"
                value={formData.confirmPassword}
                onChange={handleInputConfirmPasswordChange}
              />
              {confirmPasswordError && <div className="error-message">{confirmPasswordError}</div>}
            </div>
          </div>
          {error && <p className="error-message">{error}</p>}
          <div className="col-md-12 d-flex-center btn-form">
            <Commonbtn
              Commonbtntext={loading ? "Setting Password..." : "Set Password"}
              disabled={loading}
            />
          </div>
        </form>

        <p className="f-16 greyf6 text-center mt-3">
          Back to
          <Link to="/login">
            <strong> Login</strong>
          </Link>
        </p>
      </div>
    </section>
  );
}

export default Setpassword;
