import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import AlertRed from "../Alert/AlertRed";
import Alert from "../Alert/Alert";
import { Link, useNavigate } from "react-router-dom";

import "./MyCart.css";
// import card1 from "../Assets/Icon/card1.png";
import card2 from "../Assets/Icon/card2.png";
import card3 from "../Assets/Icon/card3.png";
import card4 from "../Assets/Icon/card4.png";
import voucher from "../Assets/Icon/voucher.svg";
import discount from "../Assets/Icon/Discount.svg";
import Input from "../Input/Input";
import SomeoneElse from "./SomeoneElse";
import CryptoJS from "crypto-js"; // Import CryptoJS for hashing
import Select from "../Input/Select";
import axios from "axios";
import { CartContext } from "../../context/CartContext";
import Commonbtn from "../Commonbtn/Commonbtn";
import Preloader from "../Preloader/Preloader";
import ribbon from '../Assets/Ribbon.svg'
import coupon from '../Assets/coupon.svg'
import EmptyCart from "./EmptyCart";


function MyCart() {
  const [totalItems, setTotalItems] = useState(0);
  // const [selectedCard, setSelectedCard] = useState("");
  const [availableCards, setAvailableCards] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pin, setPin] = useState("");
  const [allPaymentMode, setPaymentMode] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [selectedCard, setSelectedCard] = useState("gifziPlus");
  const [showConfirm, setShowConfirm] = useState(false);
  const [itemToRemove, setItemToRemove] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedCards, setSelectedCards] = useState([]);
  const [selectedCardNumber, setSelectedCardNumber] = useState("");
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");
  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const userEmail = localStorage.getItem("userEmail");
  const userMobile = localStorage.getItem("userMobile");
  const userId = localStorage.getItem("userId");
  const [alertMessage, setAlertMessage] = useState("");
  const { cartItemCountNav, updateCartCount } = useContext(CartContext);
  const [couponCode, setCouponCode] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [discountAmount, setDiscountAmount] = useState(0);
  const [couponData,setcouponData] = useState('');
  const [discount_id,setdiscount_id] = useState('');
  const Empty ={
    title:'Your cart is currently empty!',
    subtitle:'It seems like you haven’t added anything yet',
    subtext:'Start shopping to fill it up with amazing deals',
    link: '/gift-cards',
    btntext: 'Shop Now',

  }

 
  //let productInfo = "giftcard_" + userId;
  useEffect(() => {
    handleCardSelection("onlinegifziplus");
  }, []);
  
  const [productInfo, setProductInfo] = useState(`giftcard_${userId}`);
  
  const handleCardSelection = (card) => {
    setSelectedCard(card);
    setCartItems([]);
    setSelectedCountry([]);
    setTotalAmount(calculateTotal());
    setSelectedCards([]);
    setSelectedRegion("");
    setPin("");
  
    let mode = `giftcard_${userId}`; // Default mode
  
    if (card === "giftPlus") {
      mode = "giftPlus";
    } else if (card === "onlinepayment") {
      mode = "onlinepayment";
    }
  
    setPaymentMode(mode);
  
    // Dynamically update productInfo
    if (mode === "both") {
      setProductInfo(`both`);
    } else {
      setProductInfo(`giftcard_${userId}`);
    }
  
    setRefresh((prev) => !prev);
  };
  

  const [isLoading, setIsLoading] = useState(false);

  const handlePaymentWithLoader = () => {
    // Show loader
    setIsLoading(true);

    // Delay for 5 seconds
    setTimeout(() => {
      setIsLoading(false);
      handlePayment(); // Call your payment function here
    }, 5000);
  };

  const handleGifziPlusPaymentWithLoader = () => {
    // Show loader
    setIsLoading(true);

    // Delay for 5 seconds
    setTimeout(() => {
      setIsLoading(false);
      handleGifziPlusPayment(); // Call your Gifzi Plus payment function here
    }, 500);
  };


  const fetchUserCardList = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}gifzi_plus/list`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
  
      if (response.status === 401 || response.status === 403) {
        // Unauthorized, clear token and redirect to login
        navigate("/login"); // Redirect to login page
        return;
      }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
  
      if (
        data &&
        data.status === 200 &&
        data.data &&
        Array.isArray(data.data.cards)
      ) {
        // Remove duplicates based on a unique property, e.g., 'id'
        const uniqueCards = data.data.cards.filter(
          (card, index, self) =>
            index === self.findIndex((c) => c.id === card.id)
        );
  
        setSelectedCountry(uniqueCards);
      } else {
        console.error("Failed to fetch cart items:", data.message);
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    const fetchCartItems = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}cart/all`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );

        // Check if the response is ok (status in the range 200-299)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if ( data && data.message === "Cards not available.") {
          // Refresh the page if cards are not available
          window.location.reload();
        } 
        else if (
          data &&
          data.status === 200 &&
          data.data &&
          Array.isArray(data.data.cartData)
        ) {
          setCartItems(data.data.cartData);
          const totalQuantity = data.data.totalQuantity > 10 ? 10 : data.data.totalQuantity;
          setTotalItems(totalQuantity);
        } else {
          console.error("Failed to fetch cart items:", data.message);
        }
      } catch (error) {
        console.error("Error fetching cart items:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCartItems();
    fetchUserCardList();
  }, [token, refresh]);

  // Calculate total cart amount when cart items change
  useEffect(() => {
    setTotalAmount(calculateTotal());
  }, [cartItems]);

  // Check card balance API call
  const checkCardBalance = async () => {
    if (selectedCards.length >= 5) {
      setShowAlert(true);
      setAlertMessage("You can apply a maximum of 5 cards at a time.");
      setTimeout(() => setShowAlert(false), 2000);
      return; // Exit the function to prevent further processing
    }
  
    try {
      const requestBody = {
        card_id: selectedRegion,
        card_pin: pin,
      };
  
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}gifzi_plus/hold`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(requestBody),
        }
      );
  
      const data = await response.json();
      if (data.status === 200 && data.data) {
        const cardBalance = parseFloat(data.data.balance);
        const linkId = parseFloat(data.data.link_id);
  
        const usedBalance = Math.min(cardBalance, totalAmount);
  
        let newTotal = totalAmount - cardBalance;
        if (newTotal < 0) newTotal = 0;
  
        setTotalAmount(newTotal);
  
        setSelectedCountry((prevCountries) => {
          const updatedCountries = prevCountries.filter(
            (country) => country.id != selectedRegion
          );
          return updatedCountries;
        });
        const selectedCard = selectedCountry.find(
          (region) => region.id == selectedRegion
        );
  
        if (
          selectedCard &&
          !selectedCards.some(
            (card) => card.card_number === selectedCard.card_number
          )
        ) {
          setSelectedCards((prevCards) => [
            ...prevCards,
            {
              id: selectedRegion,
              link_id: linkId, // Assuming `link_id` is part of the card object
              card_number: selectedCard.card_number,
              balance: usedBalance.toFixed(2), // Store balance as a string with 2 decimal places
            },
          ]);
  
          setSelectedCardNumber(
            `${selectedCard.card_number} (${cardBalance.toFixed(2)})`
          );
  
          console.log("setSelectedCardNumber", selectedCard.card_number);
        }
  
        setSelectedRegion("");
        setPin("");
  
        // Switch to "both" mode if the user adds a card
        if (selectedCards.length > 0 || newTotal > 0) {
          setPaymentMode("both");
          setProductInfo(`both`);
        }
  
        console.log("New Cart Total:", newTotal);
        setShowSuccessAlert(true);
        setAlertMessage("Gifzi Plus card applied successfully");
        setTimeout(() => {
          setShowSuccessAlert(false);
        }, 3000);
      } else {
        console.error("Failed to check card balance:", data.message);
        setShowAlert(true);
        setAlertMessage(data.message);
        setTimeout(() => setShowAlert(false), 2000);
      }
    } catch (error) {
      console.error("Error checking card balance:", error);
    }
  };
  
console.log(totalAmount,'TR');
  
  const removeCard = async (indexToRemove) => {
    // Find the card that is being removed from selected cards
    const cardToRemove = selectedCards[indexToRemove];
  
    if (cardToRemove) {
      // Convert balance to float and add back to total amount
      const cardBalance = parseFloat(cardToRemove.balance);
      let updatedTotal = totalAmount + cardBalance;
  
      // Ensure that totalAmount doesn't go below zero
      if (updatedTotal < 0) updatedTotal = 0;
  
      setTotalAmount(updatedTotal); // Update the total amount state
      console.log("Updated Total Amount after removing card:", updatedTotal);
  
      // Prepare the request body for the API call
      const requestBody = {
        card_id: cardToRemove.id, // Send the card id to be removed
      };
  
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}gifzi_plus/release`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token, // Assuming you have token for authorization
          },
          body: JSON.stringify(requestBody),
        });
  
        const data = await response.json();
        if (data.status === 200) {
          let mode = `giftcard_${userId}`; // Default mode
  
    setPaymentMode(mode);
  
    // Dynamically update productInfo
    if (mode === "both") {
      setProductInfo(`giftcard_${userId}_both`);
    } else {
      setProductInfo(`giftcard_${userId}`);
    }
  
          const updatedCards = selectedCards.filter((_, index) => index !== indexToRemove);
          setSelectedCards(updatedCards);
  
          // Optionally, add the card back to available cards list
          setSelectedCountry((prevAvailableCards) => [...prevAvailableCards, cardToRemove]);
  
          setShowSuccessAlert(true);
          setAlertMessage("Card removed successfully");
         
          setTimeout(() => setShowSuccessAlert(false), 3000);
        } else {
          console.error("Failed to remove card:", data.message);
          setShowAlert(true);
          setAlertMessage(data.message);
          setTimeout(() => setShowAlert(false), 2000);
        }
      } catch (error) {
        console.error("Error removing card:", error);
        setShowAlert(true);
        setAlertMessage("An error occurred while removing the card.");
        setTimeout(() => setShowAlert(false), 2000);
      }
    }
  };
  
  
  
  
  



  const handleQuantityChange = async (cartId, sku, type) => {
    setCartItems((prevItems) => {
      const updatedItems = prevItems.map((item) => {
        if (item.id === cartId) {
          let newQty = type === "increment" ? item.qty + 1 : item.qty - 1;
  
          // Check the total quantity of items in the cart
          const totalItemsInCart = prevItems.reduce((total, item) => total + item.qty, 0);
  
          // If the increment would exceed the limit, show an alert
          if (type === "increment" && totalItemsInCart >= 10) {
            setShowAlert(true);
            setAlertMessage("You cannot add more than 10 items!");
            setTimeout(() => setShowAlert(false), 2000);
            return item; // Don't change quantity if limit is reached
          }
  
          // Ensure the new quantity doesn't go below 1 (i.e., not zero or negative)
          if (newQty < 1) {
            newQty = 1;
          }
  
          // Update cart count if quantity is updated
          if (type === "increment") {
            updateCartCount(totalItemsInCart + 1);  // Increment total count
          } else if (newQty < item.qty) {
            updateCartCount(totalItemsInCart - 1);  // Decrement total count
          }
  
          // Update the item quantity
          updateCartItemQuantity(cartId, sku, newQty, item.amount);
          return { ...item, qty: newQty };
        }
        return item;
      });
  
      // Recalculate the total number of items in the cart (with the quantity limits applied)
      const newTotalItems = updatedItems.reduce((total, item) => total + item.qty, 0);
      setTotalItems(Math.min(newTotalItems, 10));  // Ensure the total doesn't exceed 10
  
      setSelectedCards([]);
      fetchUserCardList();
      return updatedItems;
    });
  };
  

  const updateCartItemQuantity = async (cartId, sku, qty, productPrice) => {
    const requestBody = {
      cart_id: cartId,
      sku: sku,
      qty: qty,
      product_price: productPrice,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}cart/update`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();
      if (data.status !== 200) {
        console.error("Failed to update item quantity:", data.message);
      }
    } catch (error) {
      console.error("Error updating item quantity:", error);
    }
  };

  const handleRegionChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedRegion(selectedValue);
  };

  const handleRemoveItem = (cartId, qty) => {
    setItemToRemove(cartId);
    setItemToRemove({ id: cartId, qty: qty });
    setShowConfirm(true);
  };

  const handleConfirmRemove = async () => {
    try {
      const requestBody = {
        cart_id: itemToRemove.id,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}cart/remove`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();
      if (data.status === 200) {
        updateCartCount((prevCount) => {
          const newCount = prevCount - itemToRemove.qty;
          return newCount;
        });
        setTotalItems((prevCount) => {
          const newCount = prevCount - itemToRemove.qty;
          return newCount;
        });
        

        setCartItems((prevItems) =>
          prevItems.filter((item) => item.id !== itemToRemove.id)
        );
        setItemToRemove({ id: null, qty: null });
        setShowSuccessAlert(true);
        setAlertMessage("Item deleted successfully");
        setCouponCode(''); // Clear the coupon input field
        setcouponData(null); // Remove the coupon data from the state
        setError(null); // Clear any error message
        setSuccessMessage(''); // Clear success message
      
        // Recalculate the total after coupon removal
        const updatedTotal = calculateTotal(); // Invoke calculateTotal to get the recalculated total
        setTimeout(() => setShowSuccessAlert(false), 2000);
      } else {
        console.error("Failed to remove item:", data.message);
      }
    } catch (error) {
      console.error("Error removing item:", error);
    } finally {
      setShowConfirm(false);
    }
  };

  const handleCancelRemove = () => {
    setShowConfirm(false);
  };

  // Handle PIN input change
  const handlePinChange = (e) => {
    setPin(e.target.value);
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      // Start with the base price of the item
      let discountedPrice = item.amount;
  
      // Apply flat discount if available
      if (item.flat_discount) {
        discountedPrice -= (item.amount * item.flat_discount) / 100;
      }
  
      // Apply coupon discount if SKU matches and coupon data is available
      if (couponData && item.sku === couponData.sku) {
        if (couponData.discount_type === "BY_FIXED") {
          // Subtract the fixed discount amount
          discountedPrice -= couponData.discount_amount;
        } else if (couponData.discount_type === "BY_PERCENT") {
          // Calculate percentage discount
          const percentageDiscount = (discountedPrice * couponData.discount_max) / 100;
          discountedPrice -= percentageDiscount;
        } else if (couponData.discount_type === "TO_PERCENT") {
          // For `TO_PERCENT`, calculate both discount methods
          const maxDiscount = (discountedPrice * couponData.discount_max) / 100; // Percentage discount
          const fixedDiscount = couponData.discount_amount; // Fixed amount discount
  
          // Apply the smaller discount amount
          const appliedDiscount = Math.min(maxDiscount, fixedDiscount);
          discountedPrice -= appliedDiscount;
        }
      }
  
      // Ensure that the discounted price doesn't go below 0
      discountedPrice = Math.max(discountedPrice, 0);
  
      // Add discounted price multiplied by quantity to the total
      return total + parseFloat(discountedPrice) * item.qty;
    }, 0);
  };
  
  
  
  
  
  
  useEffect(() => {
    // Update total amount after applying flat discount and coupon discount
    setTotalAmount(calculateTotal());
  }, [cartItems, discountAmount]); // Recalculate when cartItems or discountAmount changes
 

  const handlePaymentDirect = async () => {
    const generateTxnId = () => {
      const randomString = Math.random().toString(36).substr(2, 8);
      const timestamp = Date.now();
      return `${randomString}${timestamp}`;
    };

    const txnid = generateTxnId();
    const paymentDetails = {
      payment_mode: 1,
      status: 1,
      is_gift: 0,
      online_payment_detail: {
        transaction_id: txnid,
        amount: calculateTotal().toString(),
      },
    };
    try {
      //const response = await axios.post(`${process.env.REACT_APP_BASE_API_URL}/order/checkout`, paymentDetails);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}order/checkout`,
        paymentDetails,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      console.log("Payment Success:", response.data);
    } catch (error) {
      setShowAlert(true);
      setAlertMessage(error.response.data.message);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      console.error("Payment Error:", error.response.data);
    }
  };
  
  const [isGift, setIsGift] = useState(false);
  const [giftDetail, setGiftDetail] = useState({
    // firstname: "",
    email: "",
    // telephone: "",
    gift_message: "",
  });

    const handleGifziPlusPayment = async () => {
    const txnid = `${Math.random().toString(36).substr(2, 8)}${Date.now()}`;

    const paymentDetails = {
      payment_mode: 0,
      status: 1,
      discount_id:discount_id || null,
      is_gift: isGift ? 1 : 0,
      ...(isGift && { gift_detail: giftDetail }),
      card_payment_detail: selectedCards.map((card) => ({
        id: card.id,
        link_id: card.link_id,
        amount: card.balance || 0,
      })),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}order/checkout`,
        paymentDetails,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (response.status == 200) {
        setShowSuccessAlert(true);
        setAlertMessage(response.data.message);
        setTimeout(() => {
          console.log("SEjal");
          setShowSuccessAlert(<Preloader/>);
          navigate(`/successpage?transcationid=${txnid}`);
        }, 3000);
      } else {
        setShowAlert(true);
        setAlertMessage("Unexpected response status: " + response.status);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      }
    } catch (error) {
      console.log("Aditi");
      console.log("response error", error);
      setShowAlert(true);
      //setAlertMessage(error.response.data.message);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      //console.error("Payment Error:", error.response.data);
    }
  };

  console.log(productInfo,'testtttttt')
  const handlePayment = async () => {
    const key = `${process.env.REACT_APP_PAYU_KEY}`;
    const salt = `${process.env.REACT_APP_PAYU_SALT}`;
  
    try {
      const validateResponse = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}order/validate`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        }
      );
  
      const generateTxnId = () => {
        const randomString = Math.random().toString(36).substr(2, 8);
        const timestamp = Date.now();
        return `${randomString}${timestamp}`;
      };
  
      const txnid = generateTxnId();
      localStorage.setItem("txnid", txnid);
      const amount = totalAmount.toString();
      const firstname = firstName;
      const email = "test@gmail.com";
  
      let udf1 = "0"; // Default to 0
      let udf2 = "";
      let udf3 = "";
      let udf4 = "";
      let udf5 = "";
  
      // Gift Payment Flow
      if (isGift && giftDetail) {
        udf1 = "1"; 
        udf2 = giftDetail.email || "";
        udf3 = giftDetail.gift_message || "";
      }
  
      // Both Card and Gift Flow
      if (allPaymentMode === "both" && selectedCards.length > 0) {
        // udf2 = JSON.stringify(selectedCards.map(card => ({
        //   id: card.id,
        //   amount: card.balance || 0,
        // }))); // Encode card details as JSON
        udf2 = JSON.stringify(selectedCards)
      }
  
      const udf6 = "";
      const udf7 = "";
      const udf8 = "";
      const udf9 = "";
      const udf10 = "";
  
      const hashString = `${key}|${txnid}|${amount}|${productInfo}|${firstname}|${email}|${udf1}|${udf2}|${udf3}|${udf4}|${udf5}|${udf6}|${udf7}|${udf8}|${udf9}|${udf10}|${salt}`;
      const hash = CryptoJS.SHA512(hashString).toString(CryptoJS.enc.Hex);
  
      const form = document.createElement("form");
      form.action = `${process.env.REACT_APP_PAYU_URL}`;
      form.method = "POST";
  
      const fields = [
        { name: "key", value: key },
        { name: "txnid", value: txnid },
        { name: "productinfo", value: productInfo },
        { name: "amount", value: amount },
        { name: "email", value: email },
        { name: "firstname", value: firstname },
        { name: "lastname", value: lastName },
        { name: "paymentmode", value: allPaymentMode },
        { name: "surl", value: `${process.env.REACT_APP_PAYU_SUCCESS_URL}` },
        { name: "furl", value: `${process.env.REACT_APP_PAYU_FAILURE_URL}` },
        { name: "phone", value: userMobile },
        { name: "udf1", value: udf1 },
        { name: "udf2", value: udf2 },
        { name: "udf3", value: udf3 },
        { name: "udf4", value: udf4 },
        { name: "udf5", value: udf5 },
        { name: "udf6", value: udf6 },
        { name: "udf7", value: udf7 },
        { name: "udf8", value: udf8 },
        { name: "udf9", value: udf9 },
        { name: "udf10", value: udf10 },
        { name: "hash", value: hash },
      ];
  
      fields.forEach(({ name, value }) => {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = name;
        input.value = value;
        form.appendChild(input);
      });
  
      document.body.appendChild(form);
      form.submit();
    } catch (error) {
      setShowAlert(true);
      setAlertMessage(error.response?.data?.message || "An error occurred");
      setTimeout(() => {
        setShowAlert(false);
        navigate("/profile");
      }, 4000);
  
      console.error("Payment Error:", error.response?.data || error.message);
    }
  };
  

  const handleCouponChange = (value) => {
    setCouponCode(value);
  };

  const handleApplyCoupon = async () => {
    if (!couponCode) {
      setError('Coupon code is required!');
      return;
    }
  
    try {
      // Assuming baseUrl is defined, replace it accordingly
      const baseUrl = process.env.REACT_APP_BASE_API_URL; // Use your environment variable or static URL
      const response = await axios.get(`${baseUrl}cart/check_coupon?coupon=${couponCode}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
  
      if (response.data.status === 200) {
        const coupon = response.data.data;
        const matchingSKU = cartItems.some((item) => item.sku === coupon.sku);
  
        if (!matchingSKU) {
          // If no SKU matches, show an error message
          setError('There is no coupon for the products in your cart.');
          setSuccessMessage('');
          setTimeout(() => {
            setError('');
          }, 3000);
          return;
        }
  
        // If SKU matches, apply the coupon
        setDiscountAmount(coupon.discount_amount);
        setcouponData(coupon);
        setdiscount_id(coupon.discount_id);
        setSuccessMessage('Coupon applied successfully!');
        setTimeout(() => {
          setSuccessMessage('');
        }, 2000);
        setError('');
      } else {
        setError(response.data.message || 'Invalid coupon code');
        setSuccessMessage('');
      }
    } catch (error) {
      setError('Offer expired or not found.');
      setSuccessMessage('');
      console.error('Error applying coupon:', error);
    }
  
    // Clear error after a timeout
    setTimeout(() => {
      setError('');
    }, 3000);
  };
  
  console.log(calculateTotal(),'TOTAL')
  
  const handleRemoveCoupon = () => {
    // Reset coupon-related states
    setCouponCode(''); // Clear the coupon input field
    setcouponData(null); // Remove the coupon data from the state
    setError(null); // Clear any error message
    setSuccessMessage(''); // Clear success message
    setError('Coupon removed successfully.');
    // Recalculate the total after coupon removal
    const updatedTotal = calculateTotal(); // Invoke calculateTotal to get the recalculated total
    setTotalAmount(updatedTotal); 
    setTimeout(() => {
      setError('');
    }, 1000);
  };
  useEffect(() => {
    const updatedTotal = calculateTotal();
    setTotalAmount(updatedTotal);
    
  }, [cartItems, couponData]); // Recompute total when cartItems or couponData changes
  

 
  return (
    <div className="cart p-60">
    

      <div className="container">
    
        <div className="row">
        <div className="col-md-12">
             {/* <h1>Total Items in Cart: </h1> */}
            


            

{cartItems.length === 0 && (
             <EmptyCart Empty={Empty}/>
              )}
              {cartItems.length > 0 && (
               <>
                <h3 className="f-24">Cart</h3>
                <p className="f-18 mb-3">
                You have{" "}
                <span className=" text-main f-11">{totalItems} item(s) </span>{" "}
                in your cart
              </p>
                <SomeoneElse
                onGiftChange={setIsGift}
                onGiftDetailChange={setGiftDetail}
              />
               </>
              
              )}
              
             </div>
          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="left">
           
              {/* <Link to={'/gift-cards'}>
              <Commonbtn Commonbtntext="shop now"/>
              </Link> */}

              {/* Show loading message */}
              {loading ? (
                <div>Loading cart items...</div>
              ) : (
                <>
                  {showAlert && <AlertRed title={alertMessage} />}
                  {showSuccessAlert && <Alert title={alertMessage} />}

                  {showConfirm && (
                    <>
                      <div className="overlay" /> {/* Add the overlay */}
                      <div className="confirmation-modal">
                        <h4>Are you sure you want to delete this item?</h4>
                        <div className="modal-actions d-flex-center gap-2">
                          <button
                            className="btn btn-danger"
                            onClick={handleConfirmRemove}
                          >
                            Yes
                          </button>
                          <button
                            className="btn btn-secondary"
                            onClick={handleCancelRemove}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="cart-items-list mt-3">
                    {cartItems.map((item, index) => (
                      <div
                        key={index}
                        className="cart-item-card d-flex align-items-center mb-3 gap-3 shadow-sm p-2 rounded-3"
                      >
                        <div className="position-relative">
                        <img
                          src={item.thumbnail}
                          alt={item.name}
                          height="100px"
                          width="150px"
                          className="cart-img"
                        />
                           {item.flat_discount ? (
      <i className='flat-off position-absolute'>
      <div className='ribbon position-relative right-5'>
        <img src={ribbon} height="100%" width="100%"/>
        <p className="m-0 p-0 flat-text"> {item.flat_discount}% Flat off</p>
      </div>
    </i>
  ) : null}
                        </div>

                        <div className="cart-content col-lg-6 col-md-6 col-sm-6">
  <p className="f-20">{item.name}</p>

  {/* Check if any discount is applied */}
  {(item.flat_discount || (couponData && item.sku === couponData.sku)) ? (
    <>
      {/* Original Price */}
      <p className="f-16 f-weight-600 text-muted mt-1" style={{ textDecoration: 'line-through' }}>
         Price: Rs. {parseFloat(item.amount).toFixed(2)}
      </p>

      {/* Flat Discount Price */}
      {item.flat_discount && (
        <p className="f-16 f-weight-600 text-success mt-1">
          Flat Discount: Rs. {parseFloat(item.amount - (item.amount * item.flat_discount) / 100).toFixed(2)}
        </p>
      )}

      {/* Final Price after applying coupon */}
      {couponData && item.sku === couponData.sku && (
        <p className="f-16 f-weight-600 text-success">
        Coupon Discount Applied: Rs.{" "}
        {(() => {
          let discountedPrice = item.amount;

          // Apply flat discount first
          if (item.flat_discount) {
            discountedPrice -= (item.amount * item.flat_discount) / 100;
          }

          // Apply coupon discount based on the type
          if (couponData.discount_type === "BY_FIXED") {
            discountedPrice -= couponData.discount_amount;
          } else if (couponData.discount_type === "BY_PERCENT") {
            discountedPrice -= (discountedPrice * couponData.discount_max) / 100;
          } else if (couponData.discount_type === "TO_PERCENT") {
            const maxDiscount = (discountedPrice * couponData.discount_max) / 100; // Percentage discount
            const fixedDiscount = couponData.discount_amount; // Fixed amount discount
            const appliedDiscount = Math.min(maxDiscount, fixedDiscount);
            discountedPrice -= appliedDiscount;
          }

          return parseFloat(discountedPrice).toFixed(2);
        })()}
      </p>
      )}
    </>
  ) : (
    // If no discount applied, just show the original price
    <p className="f-16 f-weight-600 mt-1">
      Price: Rs. {parseFloat(item.amount).toFixed(2)}
    </p>
  )}
</div>

                    <div className="d-flex-center qty-main gap-2">
                         <div className="d-flex align-items-center qty">
                            <button  className="btn btn-secondary" onClick={() =>
                                handleQuantityChange(   item.id,  item.sku, "decrement") } >   -
                            </button>
                            <span className="mx-2">{item.qty}</span>
                            <button
                              className="btn btn-secondary-plus"
                              onClick={() =>
                                handleQuantityChange(
                                  item.id,
                                  item.sku,
                                  "increment"
                                )}>   +
                            </button>
                          </div>
                          <div
                            className="remove"
                            onClick={() => handleRemoveItem(item.id, item.qty)}
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              className="text-danger cursor-pointer"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        
          {cartItems.length > 0 && (
            <>
            <div className="col-lg-4 col-md-12 col-sm-12 payment-detail ">
            <div>
          
  {totalAmount > 0 && (
  <>
  <form
  className={`coupon-form ${couponData ? 'coupon-applied rounded-3 p-1' : ''}`}
  style={{
    // backgroundColor: couponData ? '#E1C16E' : 'transparent',
     // Light green if coupon is applied
    transition: 'background-color 0.3s ease', // Smooth transition
    // color: couponData ? '#E1C16E' : 'transparent',
  }}
>
  <div className="form-group mb-1" >
    <label htmlFor="coupon-code" className="form-label d-flex gap-1 align-items-center">
      
     
    {couponData ? 'Applied coupon' : 'Have a coupon?'}
    </label>
    <div className={`input-group apply-coupon ${couponData ? 'couponaplied' : 'removed'}`}>

      <input
        type="text"
        id="coupon-code"
        className="form-control coupon"
        placeholder="Enter your coupon code"
        aria-label="Coupon code"
        value={couponCode}
        onChange={(e) => handleCouponChange(e.target.value.toUpperCase())}
        disabled={couponData}
      />
      {/* Apply Coupon Button */}
      {!couponData ? (
        <button
          className="btn-none  text-success  text-decoration-underline f-13 text-bold btn-apply"
          type="button"
          onClick={handleApplyCoupon}
        >
          Apply
        </button>
      ) : (
        // Remove Coupon Button
        <button
          className="btn-none text-danger text-decoration-underline f-13 text-bold ps-2"
          type="button"
          onClick={handleRemoveCoupon}
        >
          Remove
        </button>
      )}
    </div>
  </div>
</form>

  </>
)}

      </div>
      <div>
  {/* Show error message */}
  {error && <div className="error-message" style={{ color: 'red' }}>{error}</div>}

  {/* Show success message */}
  {successMessage && (
    <div className="success-message" style={{ color: 'green' }}>{successMessage}</div>
  )}
</div>
              <div className="right">
                <div className="select-card mt-2">
                  <h3 className="f-18 text-white">Payment Mode</h3>
                  <div className="d-flex-center justify-content-start gap-2 mt-2 p-1">                   
                    <div
                      className={`card active ${
                        selectedCard === "onlinegifziplus" ? "active" : "" }`} >
                      <img src={card2} alt="Bonus Card" />
                    </div>
                  </div>
                </div>

                <div className="payment-card">
                  <div className="tab-navigation">
                    {selectedCard && (
                      <>
                        <div className="tab-content mt-3">
                          {selectedCard === "onlinegifziplus" && (
                            <div className="tab-pane fade show active">
                            {selectedCards.length > 0 && (
               <ul className="apply-card">
               {selectedCards.map((cardInfo, index) => (
                   <li key={index} className="f-14 text-grey">
                 <p className="f-14 text-success d-flex align-items-center gap-1 f-weight-600">
                   <img
                   src={discount}  alt="Gift Plus Card" />
                    Applied
                 </p>
               <p className="f-12 text-white text-decoration-underline float-end cursor-pointer" 
                onClick={() => removeCard(index)}>
                  Remove card
                   </p>
                {cardInfo.card_number}{" "} Rs. {cardInfo.balance}
                     </li>
                    ))}
                </ul>
                    )}
                    
                             {totalAmount > 0 && (
                               <>
                              <div className="col-md-12 mt-3 mb-2">
                                <select
                                  name="country_id"
                                  className="form-control shadow-sm"
                                  value={selectedRegion}
                                  onChange={handleRegionChange}
                                >
                                  <option value="">Select a Card</option>
                                  {selectedCountry.map((region) => (
                                    <option key={region.id} value={region.id}>
                                      {region.card_number}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-md-12">
                              <input
                               type="text"
                               name="pin"
                               placeholder="Enter Pin"
                               className="form-control shadow-sm"
                               value={pin}
                               maxLength={6} 
                               onChange={(e) => {  
                               const value = e.target.value;  
                               if (/^\d*$/.test(value)) {
                               handlePinChange(e);  }
                                    }}
                               />

                              </div>
                              <div className="col-md-12 mb-2 mt-3">
                                <button
                                  className="text-white d-flex-center gap-2 apply-btn"
                                  onClick={checkCardBalance}
                                >
                                  <img src={voucher} alt="Gift Plus Card" />
                                  Apply Card
                                </button>
                              </div>
                              </>
                              )}
                              </div>
                          )}     
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="price">
                  <div className="col-md-12 d-flex justify-content-between mb-3 text-white f-16 mt-2">
                    <p>Total (Tax incl.)</p>
                    <p className=" f-18 f-weight-600">
                      {totalAmount.toFixed(2)}
                    </p>
                  </div>
                  {/* <div className="total-price mt-3">
                <h4>Total: Rs. {calculateTotal().toFixed(2)}</h4>
              </div> */}
   <div className="mt-3 paymnt">
      {/* Show loader if active */}
      {isLoading ? (
               <Preloader />
               // Replace with your loader design
      ) : (
        <>
          {/* Show "Proceed to Payment" for onlinegifziplus or onlinepayment, and totalAmount > 0 */}
          {(selectedCard === "onlinegifziplus" || selectedCard === "onlinepayment") && totalAmount > 0 && (
            <button className="checkout text-white mb-3 w-100" onClick={handlePaymentWithLoader}>
              Proceed to Payment
            </button>
          )}

          {/* Show "Proceed to Payment from card" only if selectedCard is "onlinegifziplus" and totalAmount is 0 */}
          {selectedCard === "onlinegifziplus" && totalAmount === 0 && (
            <button className="checkout text-white mb-3 w-100" onClick={handleGifziPlusPaymentWithLoader}>
              Proceed to Payment
            </button>
          )}

          {/* Always show the "Back Shopping" button */}
          <Link className="d-block" to="/gift-cards">
            <button className="outline-white-btn w-100">Back Shopping</button>
          </Link>
        </>
      )}
    </div>
         </div>
           </div>    
            </div>        
             </>
          )}
        </div>
      </div>
    </div>
  );
}

export default MyCart;
