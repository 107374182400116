// Preloader.js
import React from "react";
import "./Preloader.css"; // CSS for the preloader
import logo from "../Assets/logo.png"; // Import your logo image

const Preloader = () => {
  return (
    <div className="preloader">
      <div className="loader-container">
        <img src={logo} alt="Logo" className="loader-logo" />
      </div>
    </div>
  );
};

export default Preloader;
