import { faBell } from "@fortawesome/free-regular-svg-icons";
import "./List.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import EmptyCart from "../MyCart/EmptyCart";

function List() {
  const [notificationData, setNotificationData] = useState([]);
  const [clickedNotifications, setClickedNotifications] = useState([]);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const observerRef = useRef(null);
  const Empty ={
    title: "No Notifications Yet!",
    subtitle: "You're all caught up.",
    subtext: "We'll let you know when something important comes up.",
    link: '/home',
    btntext: 'Back To Home',
  }
  const limit = 10; // Number of notifications per request

  const fetchNotifications = async () => {
    if (!hasMore) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}menu/notifications?offset=${offset}&limit=${limit}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("authToken"),
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        const newNotifications = result.data.notifications || [];

        if (newNotifications.length > 0) {
          setNotificationData((prev) => [...prev, ...newNotifications]);
          setOffset((prevOffset) => prevOffset + limit);
        } else {
          setHasMore(false); // No more notifications to load
        }
      } else {
        setError("Failed to load notifications.");
      }
    } catch (err) {
      setError("An error occurred while fetching notifications.");
    }
  };

  // Initial API call to check if notifications exist
  useEffect(() => {
    fetchNotifications();
  }, []);

  // Scroll-based API loading with IntersectionObserver
  useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect();

    observerRef.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) fetchNotifications();
    });

    const currentObserver = observerRef.current;
    if (hasMore && notificationData.length > 0) {
      currentObserver.observe(document.getElementById("load-more-trigger"));
    }

    return () => currentObserver.disconnect();
  }, [hasMore, notificationData]);

  const handleNotificationClick = (index) => {
    setClickedNotifications((prevClicked) => [...prevClicked, index]);
  };

  return (
    <div className="notification p-60 bg-fb">
      <div className="container notification-list">
        <h2 className="text-center f-40">Notification</h2>
        {error && <p className="error-message">{error}</p>}
        <div className="notification-list-main bg-white p-3">
          <div>
            {notificationData.length > 0 ? (
              notificationData.map((item, index) => {
                const isActive = !clickedNotifications.includes(index);
                return (
                  <div
                    key={index}
                    className={`notification-item row ${
                      isActive ? "active" : ""
                    }`}
                    onClick={() => handleNotificationClick(index)}
                  >
                    <div className="notification-icon col-md-1">
                      <FontAwesomeIcon
                        icon={faBell}
                        className="text-white f-16"
                      />
                    </div>
                    <div className="notification-content col-md-11">
                      <h4 className="f-18 f-weight-600">{item.title}</h4>
                      <p className="f-15 grey">{item.message}</p>
                      <p className="text-muted f-14">{item.created_at}</p>
                    </div>
                  </div>
                );
              })
            ) : (
              <EmptyCart Empty={Empty}/>
            )}
            <div id="load-more-trigger" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default List;
