import React from 'react'
import Myorder from '../Components/Myorder/Myorder'
// import EmptyOrder from '../Components/Empty/EmptyOrder'

function OrderHistory() {
  return (
    <div>
       {/* <EmptyOrder/> */}
        <Myorder/>
    </div>
  )
}

export default OrderHistory