import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './terms.css'; // Adjust styles as needed

const FetchContent = ({ slug }) => {
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchContent = async () => {
      const baseUrl = `${process.env.REACT_APP_BASE_API_URL}menu/pageContent?slug=${slug}`;

      try {
        const response = await fetch(baseUrl);
        if (!response.ok) {
          throw new Error('Failed to fetch content');
        }
        const result = await response.json();

        // Access the content from the response
        if (result.data && result.data.content && result.data.content.length > 0) {
          setContent(result.data.content[0].content); // Get the content from the first item
        } else {
          setContent('No content available.'); // Handle case with no content
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchContent();
  }, [slug]); // Run when slug changes

  if (loading) {
    return <div className='loading'>Loading...</div>; // Loading state
  }

  if (error) {
    return (
      <div className='error-message'>
        <p>{error}</p>
      </div>
    ); // Error state
  }

  return (
    <div className='content'>
      <div dangerouslySetInnerHTML={{ __html: content }} /> {/* Render HTML content safely */}
    </div>
  );
};

FetchContent.propTypes = {
  slug: PropTypes.string.isRequired, // Prop type for slug
};

export default FetchContent;
