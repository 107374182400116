import React from 'react';
import Banner from '../Components/GifziPlus/Banner';
import Content from '../Components/GifziPlus/Content';
import MyCard from '../Components/GifziPlus/MyCard';

function GiftPlus() {
  const token = localStorage.getItem('authToken'); // Retrieve auth token

  return (
    <div className='position-relative'>
      <Banner />
      {token && <MyCard  limit={4} showViewAll={true} />} {/* Show Mycard only if the user is logged in */}
      <Content />
    </div>
  );
}

export default GiftPlus;
