import React from 'react'
import './Process.css'

function Process() {
    const processdata = [
        {
          title : ' Register on Gifzi',
          des: 'Create an account to access the Gifzi Plus Card, which serves as your gateway to personalized and brand gift cards.',
        },
        {
          title : 'Choose Your Cards',
          des: 'Use your Gifzi Plus Card or directly purchase from a selection of gift cards across categories like shopping, entertainment, fashion, travel, and more.',
        },
        {
          title : ' Send and Enjoy',
          des: 'Share gift cards with family, friends, employees, or clients to celebrate special occasions or express gratitude easily and thoughtfully.',
        },
      ]
  return (
    <div className='how-process text-white'>
        
        <div className='container bg-black radius-50'>

      <div className='row content' >
        {
            processdata.map((item , index)=>(
<div key={index} className='col-md-4 '>
    <h3 className='step'>{item.title}</h3>
    <p className='des'>{item.des}</p>
</div>
            )

              )
        }

      </div>

        </div>
    </div>
  )
}

export default Process