import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Input from '../Input/Input';
import pluscard from '../Assets/Icon/pluscard.svg';
import './Balance.css';
import Commonbtn from '../Commonbtn/Commonbtn';

function RechargeNow() {
  const location = useLocation();
  const [cardNumber, setCardNumber] = useState(location.state?.cardNumber || '');
  const [cardPin, setCardPin] = useState('');
  const [rechargeAmount, setRechargeAmount] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const token = localStorage.getItem("authToken");
  const cardId = location.state?.cardId; // Get card ID from location state

  const handleRecharge = async () => {
    const baseUrl = `${process.env.REACT_APP_BASE_API_URL}gifzi_plus/recharge`;

    try {
      const response = await fetch(baseUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
        body: JSON.stringify({ 
          card_number: cardNumber,
          card_pin: cardPin, // Include card pin if needed
          card_id: cardId, // Pass the hidden card ID
          amount: rechargeAmount
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setResponseMessage(`Recharge successful! Transaction ID: ${data.transaction_id}`);
        setErrorMessage('');
      } else {
        setErrorMessage(data.reason || 'Failed to recharge. Please try again.');
        setResponseMessage('');
      }
    } catch (error) {
      setErrorMessage('Error processing recharge. Please try again later.');
      setResponseMessage('');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleRecharge();
  };

  return (
    <div className='balance p-60'>
      <div className='container'>
        <h2 className='f-40 f-weight-600 mb-4'>Recharge Now</h2>
        <div className='row bg-fb shadow-sm rounded-3'>
          <div className='col-md-12 pluscrad'>
            <img
              src={pluscard}
              alt='logo'
              data-aos='fade-right'
              data-aos-delay='500'
              height='100%'
              width='100%'
            />
          </div>

          <div className='col-md-12'>
            <h3 className='f-30 mb-30'>Enter card detail to recharge!</h3>
          </div>

          <div className='col-md-12'>
            <Input
              placeholdertext='Enter here card number'
              labeltext='Card Number'
              fortext='Card Number'
              typetext='text'
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
            />
          </div>

     

          <div className='col-md-12'>
            <Input
              placeholdertext='Enter recharge amount'
              labeltext='Recharge Amount'
              fortext='Recharge Amount'
              typetext='number'
              value={rechargeAmount}
              onChange={(e) => setRechargeAmount(e.target.value)}
            />
          </div>

          <div className='col-md-12 mt-3'>
            <Commonbtn Commonbtntext='Recharge Now' onclicktext={handleSubmit} />
          </div>

          {responseMessage && (
            <div className='col-md-12 mt-3'>
              <p className='text-success'>{responseMessage}</p>
            </div>
          )}

          {errorMessage && (
            <div className='col-md-12'>
              <p className='text-danger'>{errorMessage}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RechargeNow;
